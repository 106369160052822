import { Text } from '@chakra-ui/react';
import { MarketKind } from '@derivadex/types';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { getSelectedMarket } from 'store/market/selectors';
import { fixedExpiryFuturesQuarterlyTimeToDelivery } from 'utils/time_utils';

import CommonStatsItem from './CommonStatsItem';
import { IStatsItem } from './Stats';

export default function TimeToDelivery({
    id,
    isDesktopView,
    width,
}: {
    id: number;
    isDesktopView: boolean;
    width: number | undefined;
}) {
    const selectedMarket = useSelector(getSelectedMarket);
    const shouldDisplayFundingCountdown = selectedMarket?.kind !== MarketKind.FixedExpiryFuture;

    const timeToDeliveryValue = () => {
        if (selectedMarket) {
            return (
                <Text>
                    {fixedExpiryFuturesQuarterlyTimeToDelivery(
                        selectedMarket.symbol.charAt(selectedMarket.symbol.length - 1) as 'H' | 'M' | 'U' | 'Z',
                    )}
                </Text>
            );
        }
    };

    const item: IStatsItem = {
        header: t('timeToDelivery'),
        value: timeToDeliveryValue(),
        visibleWidthOnDesktop: 1200,
        valueTooltip: t('timeToDeliveryTooltip'),
        thousandSeparator: false,
        decimalScale: 0,
        hide: shouldDisplayFundingCountdown,
    };
    return <CommonStatsItem id={id} isDesktopView={isDesktopView} item={item} width={width} />;
}
