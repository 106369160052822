import { Flex, Grid, GridItem, Heading, Icon, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { useScreenSize } from 'hooks/useScreenSize';
import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { UIViewState, UPDATE_CURRENT_VIEW } from 'store/ui/slice';

import Layout from '../components/Layout';
export default function Vaults() {
    const isDesktopView = useScreenSize();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(UPDATE_CURRENT_VIEW({ view: UIViewState.VAULTS }));
    }, []);

    return (
        <Layout>
            <></>
        </Layout>
    );
}
