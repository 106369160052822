import { BigNumber } from '@0x/utils';
import { Text } from '@chakra-ui/react';
import { NumericFormat } from 'react-number-format';

export default function NumberFormatMinimumValue({
    value,
    useCurrencyPrefix = false,
}: {
    value: BigNumber | null;
    useCurrencyPrefix?: boolean;
}) {
    let adjustedValue: string | number = '';
    let prefix = '';

    if (!value) {
        return <Text>-</Text>;
    } else if (value.eq(0)) {
        adjustedValue = 0;
    } else if (value.gt(0)) {
        adjustedValue = BigNumber.max(value, 0.01).toNumber();
        if (value.lt(0.01)) {
            prefix = '<';
        }
    } else if (value.lt(0)) {
        adjustedValue = BigNumber.min(value, -0.01).toNumber();
        if (value.gt(-0.01)) {
            adjustedValue = 0.01;
            prefix = '>-';
        }
    }
    return (
        <NumericFormat
            value={adjustedValue}
            displayType={'text'}
            thousandSeparator={true}
            fixedDecimalScale
            decimalScale={2}
            prefix={prefix + (useCurrencyPrefix ? '$' : '')}
        />
    );
}
