import { ActionType } from '@derivadex/types';
import { getErrorMessage, getFrontendLogger } from '@derivadex/utils';
import { getStatsApiAggregationsUrl } from 'store/config/selectors';
import { buildUrl, FetchDataState, makeRequest, parseTopTradersResponse } from 'store/requestUtils';
import { UIViewState, UPDATE_CURRENT_VIEW } from 'store/ui/slice';
import { all, call, fork, putResolve, select, takeLatest } from 'typed-redux-saga/macro';

import {
    getLeaderboardDataCurrentPage,
    getLeaderboardDataCursor,
    getLeaderboardOrdering,
    getLeaderboardTraderOrdering,
} from './selectors';
import {
    FETCH_LEADERBOARD_DATA,
    SET_LEADERBOARD_CURRENT_PAGE,
    SET_LEADERBOARD_DATA,
    SET_LEADERBOARD_ORDERING,
    SET_TRADER_LEADERBOARD_DATA,
} from './slice';

function* handleFetchLeaderboardData(action: ReturnType<typeof FETCH_LEADERBOARD_DATA>): Generator {
    try {
        const payload = action.payload;
        const currentPage = yield* select(getLeaderboardDataCurrentPage);
        const cursor = yield* select(getLeaderboardDataCursor);
        const traderOrdering = yield* select(getLeaderboardTraderOrdering);
        const ordering = yield* select(getLeaderboardOrdering);
        yield* putResolve(UPDATE_CURRENT_VIEW({ view: UIViewState.LEADERBOARD }));

        if (payload.page === FetchDataState.NextPage) {
            const isOrderingChanging =
                traderOrdering !== payload.orderBy ||
                (traderOrdering === payload.orderBy && ordering !== payload.order);
            const statsApiUrl = yield* select(getStatsApiAggregationsUrl);
            let url = '';
            url = buildUrl(statsApiUrl, { account: payload.traderFilter! }, 'traders', {
                limit: 1,
                cursor: undefined,
                topTradersOrdering: 'volume',
                order: 'desc',
            });
            const traderScopedResponse = yield* call(makeRequest, url);
            const parsedTraderScopedResponse = yield* call(parseTopTradersResponse, traderScopedResponse);

            getFrontendLogger().log('trader context leaderboard parsed response', parsedTraderScopedResponse);
            if (parsedTraderScopedResponse.traders && parsedTraderScopedResponse.traders.length > 0) {
                yield* putResolve(SET_TRADER_LEADERBOARD_DATA({ traderData: parsedTraderScopedResponse.traders[0] }));
            }
            url = buildUrl(statsApiUrl, undefined, 'traders', {
                limit: 10,
                cursor: cursor !== null && !isOrderingChanging ? cursor : undefined,
                topTradersOrdering: payload.orderBy,
                order: payload.order,
            });
            const response = yield* call(makeRequest, url);
            const parsedResponse = yield* call(parseTopTradersResponse, response);

            getFrontendLogger().log('leaderboard parsed response', parsedResponse);

            yield* putResolve(
                SET_LEADERBOARD_DATA({
                    paginatedTopTradersResponse: parsedResponse,
                    shouldClearCache: isOrderingChanging,
                }),
            );
            yield* putResolve(SET_LEADERBOARD_CURRENT_PAGE(isOrderingChanging ? 1 : currentPage + 1));
            yield* putResolve(SET_LEADERBOARD_ORDERING({ tradersOrdering: payload.orderBy, order: payload.order }));
        } else {
            yield* putResolve(SET_LEADERBOARD_CURRENT_PAGE(currentPage - 1));
        }
    } catch (error: any) {
        getFrontendLogger().logError('caught exception while fetching leaderboard data', getErrorMessage(error));
    }
}

export function* watchLeaderboardData() {
    yield* takeLatest(ActionType.FETCH_LEADERBOARD_DATA, handleFetchLeaderboardData);
}

/**
 * The leaderboard saga layer is responsable for handling side effects for the leaderboard page
 */
export const leaderboardSaga = function* root() {
    yield* all([fork(watchLeaderboardData)]);
};
