import { BigNumber } from '@0x/utils';
import { convertTickSize, OrderSide } from '@derivadex/types';
import { getFrontendLogger, orderSizeAfterLeverageOrder } from '@derivadex/utils';
import { Intent } from 'components/Marketplace/BuySell/Tabs/OrderForm';
import { FixedSize } from 'components/Marketplace/BuySell/Tabs/PercentageButtons';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useStore } from 'react-redux';
import { getMarkPricesAsList, getSelectedMarket } from 'store/market/selectors';
import { AppState } from 'store/slices';
import { getPositionsAsList, getSelectedStrategyAvailableCollateral } from 'store/strategy/selectors';
import { FRONTEND_MAX_LEVERAGE_PERCENTAGE } from 'utils/constants';

export function useOrderAmountAndLeverageUpdate(
    orderSide: OrderSide,
    setLeverageState: Dispatch<SetStateAction<Intent | null>>,
    setLeverage: Dispatch<SetStateAction<number>>,
    price: string,
    maxLeverage: number,
    triggerUpdate: FixedSize,
    setTriggerUpdate: Dispatch<SetStateAction<FixedSize>>,
    setAmount: Dispatch<SetStateAction<string>>,
) {
    const store = useStore();
    const state = store.getState() as AppState;
    useEffect(() => {
        if (triggerUpdate !== FixedSize.None) {
            const currentStrategyAvailableCollateral = getSelectedStrategyAvailableCollateral(state);
            const market = getSelectedMarket(state);
            const prices = getMarkPricesAsList(state);
            const positions = getPositionsAsList(state);
            if (market === undefined) return;
            const multiplier =
                triggerUpdate === FixedSize.One
                    ? 0.25
                    : triggerUpdate === FixedSize.Two
                    ? 0.5
                    : triggerUpdate === FixedSize.Three
                    ? 0.75
                    : FRONTEND_MAX_LEVERAGE_PERCENTAGE;
            const leverage = new BigNumber(maxLeverage * multiplier).dp(3);
            const makerAmount = orderSizeAfterLeverageOrder(
                positions,
                new BigNumber(currentStrategyAvailableCollateral || 0).toNumber(),
                prices,
                orderSide,
                leverage.toNumber(),
                new BigNumber(price || 0).toNumber(),
                market.symbol,
                convertTickSize(new BigNumber(market.minOrderSize).toNumber()),
            );

            if (leverage.isLessThanOrEqualTo(maxLeverage)) {
                setAmount(makerAmount.toString());
                setLeverage(leverage.toNumber());
                setLeverageState(Intent.NONE);
                setTriggerUpdate(FixedSize.None);
            } else {
                getFrontendLogger().logError(`Leverage cannot be bigger than ${maxLeverage}x`);
            }
        }
    }, [triggerUpdate]);

    return <></>;
}
