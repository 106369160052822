import { BigNumber } from '@0x/utils';
import {
    CheckpointedStrategy,
    CheckpointedTrader,
    GuardedDepositInfo,
    KycRequest,
    MaximumWithdrawal,
    OperatorConfig,
    Token,
    TokenBalance,
    TokenMetaData,
    UIStrategy,
} from '@derivadex/types';
import {
    constructKYCAuthRequest,
    constructKYCStatusRequest,
    constructKYCTestAccountRequest,
    createKycRequestTypedData,
    EIP712TypedDataEthers,
    encodeStringIntoBytes32,
    getErrorMessage,
    getFrontendLogger,
    postKYCUpdateRequest,
    transformTypedDataForEthers,
} from '@derivadex/utils';
import {
    getBlockNumber,
    readContract,
    readContracts,
    signTypedData,
    waitForTransactionReceipt,
    watchContractEvent,
    writeContract,
} from '@wagmi/core';
import { config } from 'config';
import { initialRuntimeConfig } from 'config/runtimeConfig';
import { utils } from 'ethers';
import _ from 'lodash';
import { Abi, Address } from 'viem';

import { ERC20ContractAbi } from '../contract-artifacts/abis';
import { DDX_APPLICATION_ID } from './constants';
import { encryptIntent } from './encrypt';
import { getTokenForSymbol, mapTokensMetadataToTokens, unitsInTokenAmount } from './tokens';
import { buildStrategyData, buildTraderData, buildWithdrawalData, buildWithdrawDDXAmount } from './web3';

export async function callApproveToken(
    tokenAddress: Address,
    approveAmount: BigNumber,
    decimals: number,
    abi: Abi,
    address: string,
): Promise<boolean> {
    const amount = unitsInTokenAmount(approveAmount.toString(), decimals).toString(10);
    try {
        const txn = await writeContract(config, {
            abi,
            address: tokenAddress,
            functionName: 'approve',
            args: [address, amount],
        });

        const receipt = await waitForTransactionReceipt(config, {
            hash: txn,
        });

        getFrontendLogger().log(`callApproveToken with tx hash ${receipt.transactionHash} -> `, receipt);
        return receipt !== undefined;
    } catch (error: any) {
        throw new Error(`System malfunction: Could not approve token. ${getErrorMessage(error)}`);
    }
}

export async function deposit(
    usdcAddress: Address,
    usdcContractAbi: Abi,
    collateralKYCContractAddress: Address,
    collateralKYCContractAbi: Abi,
    traderAddress: Address,
    strategyId: string,
    amount: string,
    operatorConfig: OperatorConfig,
    chainId: number,
    deployment: string,
    verifyingContractAddress: string,
): Promise<any> {
    try {
        const strategy = encodeStringIntoBytes32(strategyId);
        const result: any = await readContract(config, {
            abi: usdcContractAbi,
            address: usdcAddress,
            functionName: 'decimals',
        });
        const baseAmount = unitsInTokenAmount(amount, +result).toString(10);

        const depositData = await getDepositSignature(
            traderAddress,
            operatorConfig,
            chainId,
            deployment,
            verifyingContractAddress,
        );

        const txn = await writeContract(config, {
            abi: collateralKYCContractAbi,
            address: collateralKYCContractAddress,
            functionName: 'deposit',
            args: [usdcAddress, strategy, baseAmount, depositData.expiryBlock, depositData.signature],
        });

        const receipt = await waitForTransactionReceipt(config, {
            hash: txn,
        });

        getFrontendLogger().log(`Deposit USDC with tx hash ${receipt.transactionHash} -> `, receipt);
        return { txHash: receipt.transactionHash, blockNumber: parseInt(receipt.blockNumber.toString()) };
    } catch (error: any) {
        throw new Error(
            `System malfunction: Could not read contract for function decimals OR deposit. ${getErrorMessage(error)}`,
        );
    }
}

export async function depositDDX(
    ddxContractAddress: Address,
    ddxContractAbi: Abi,
    stakeKYCContractAddress: Address,
    stakeKYCContractAbi: Abi,
    traderAddress: Address,
    amount: string,
    operatorConfig: OperatorConfig,
    chainId: number,
    deployment: string,
    verifyingContractAddress: string,
): Promise<{ txHash: string; blockNumber: number }> {
    try {
        if (amount === undefined) {
            throw new Error(
                `Parameters passed to depositDDX contract call are undefined ${{
                    amount,
                    ddxContractAddress,
                    stakeKYCContractAddress,
                    traderAddress,
                }}`,
            );
        }
        const result: any = await readContract(config, {
            abi: ddxContractAbi,
            address: ddxContractAddress,
            functionName: 'decimals',
        });
        const baseAmount = unitsInTokenAmount(amount, +result).toString(10);
        const depositData = await getDepositSignature(
            traderAddress,
            operatorConfig,
            chainId,
            deployment,
            verifyingContractAddress,
        );

        const txn = await writeContract(config, {
            abi: stakeKYCContractAbi,
            address: stakeKYCContractAddress,
            functionName: 'depositDDX',
            args: [baseAmount, depositData.expiryBlock, depositData.signature],
        });

        const receipt = await waitForTransactionReceipt(config, {
            hash: txn,
        });

        getFrontendLogger().log(`Deposit DDX with tx hash ${receipt.transactionHash} -> `, receipt);
        return { txHash: receipt.transactionHash, blockNumber: parseInt(receipt.blockNumber.toString()) };
    } catch (error: any) {
        throw new Error(
            `System malfunction: Could not read contract for function decimals OR depositDDX. ${getErrorMessage(
                error,
            )}`,
        );
    }
}

export async function readTokenInfo(
    ethAccount: Address,
    tokenAddress: Address,
    tokenAbi: Abi,
    derivadexAddress: Address,
    token: Token,
): Promise<TokenBalance> {
    try {
        if (ethAccount === undefined || derivadexAddress === undefined) {
            throw new Error(
                `Parameters passed to readTokenInfo contract call are undefined ${{
                    ethAccount,
                    tokenAddress,
                    derivadexAddress,
                    token: JSON.stringify(token),
                }}`,
            );
        }

        const contract = {
            address: tokenAddress,
            abi: tokenAbi,
        } as const;

        const [decimals, balance, allowance]: any = await readContracts(config, {
            contracts: [
                {
                    ...contract,
                    functionName: 'decimals',
                },
                {
                    ...contract,
                    functionName: 'balanceOf',
                    args: [ethAccount],
                },
                {
                    ...contract,
                    functionName: 'allowance',
                    args: [ethAccount, derivadexAddress],
                },
            ],
        });
        getFrontendLogger().log(decimals, balance, allowance);

        const allowanceFormatted = utils.formatUnits(allowance.result, decimals.result);
        const balanceFormatted = utils.formatUnits(balance.result, decimals.result);
        const allowanceAmount = parseFloat(allowanceFormatted);
        const balanceAmount = parseFloat(balanceFormatted);

        return {
            token: token,
            balance: new BigNumber(balanceAmount),
            isUnlocked: new BigNumber(allowanceAmount).gt(0),
            allowanceAmount: new BigNumber(allowanceAmount),
        };
    } catch (error: any) {
        throw new Error(
            `System malfunction: Could not read contract for function decimals OR balanceOf OR allowance. ${getErrorMessage(
                error,
            )}`,
        );
    }
}

export async function getGuardedDepositInfo(
    collateralContractAddress: Address,
    collateralContractAbi: Abi,
): Promise<GuardedDepositInfo> {
    try {
        const [numberDepositedAddresses, maxDepositedAddresses, minimumDeposit]: any = await readContract(config, {
            address: collateralContractAddress as Address,
            abi: collateralContractAbi,
            functionName: 'getGuardedDepositInfo',
        });
        getFrontendLogger().log(
            new BigNumber(numberDepositedAddresses).toString(),
            new BigNumber(maxDepositedAddresses).toString(),
            new BigNumber(minimumDeposit).toString(),
        );
        return {
            numberDepositedAddresses: new BigNumber(numberDepositedAddresses.toString()),
            maxDepositedAddresses: new BigNumber(maxDepositedAddresses.toString()),
            minimumDeposit: new BigNumber(minimumDeposit.toString()),
        };
    } catch (error: any) {
        throw new Error(
            `System malfunction: Could not read contract for function getGuardedDepositInfo. ${getErrorMessage(error)}`,
        );
    }
}

export async function withdraw(
    usdcAddress: Address,
    usdcContractAbi: Abi,
    collateralContractAddress: Address,
    collateralContractAbi: Abi,
    currentStrategy: UIStrategy,
    checkpointedStrategy: CheckpointedStrategy,
    allowedAmount: BigNumber,
    merkleProof: string,
): Promise<{ txHash: string; blockNumber: number }> {
    try {
        const decimals: any = await readContract(config, {
            abi: usdcContractAbi,
            address: usdcAddress,
            functionName: 'decimals',
        });

        const strategyId = encodeStringIntoBytes32(currentStrategy.strategy);
        const strategyIdHash = currentStrategy.strategyHash;

        const withdrawalData = buildWithdrawalData(usdcAddress, +decimals.toString(), allowedAmount);
        const strategyData = buildStrategyData(usdcAddress, strategyId, currentStrategy, checkpointedStrategy);

        const txn = await writeContract(config, {
            abi: collateralContractAbi,
            address: collateralContractAddress,
            functionName: 'withdraw',
            args: [strategyIdHash, withdrawalData, strategyData, merkleProof],
        });

        const receipt = await waitForTransactionReceipt(config, {
            hash: txn,
        });

        getFrontendLogger().log(`Withdraw USDC with txhash ${receipt.transactionHash} -> ${receipt}`);
        return { txHash: receipt.transactionHash, blockNumber: parseInt(receipt.blockNumber.toString()) };
    } catch (error: any) {
        throw new Error(
            `System malfunction: Could not read contract for function decimals OR withdraw. ${getErrorMessage(error)}`,
        );
    }
}

export async function withdrawDDX(
    stakeContractAddress: Address,
    stakeContractAbi: Abi,
    allowedAmount: BigNumber,
    merkleProof: string,
    checkpointedTrader: CheckpointedTrader,
): Promise<{ txHash: string; blockNumber: number }> {
    try {
        const ethersAmount = buildWithdrawDDXAmount(allowedAmount);
        const zeroAddress = '0x0000000000000000000000000000000000000000';
        const traderData = buildTraderData(
            zeroAddress,
            checkpointedTrader.availDDX,
            checkpointedTrader.lockedDDX,
            checkpointedTrader.payFeesInDDX,
        );

        const txn = await writeContract(config, {
            abi: stakeContractAbi,
            address: stakeContractAddress,
            functionName: 'withdrawDDX',
            args: [ethersAmount.toFixed(), traderData, merkleProof],
        });

        const receipt = await waitForTransactionReceipt(config, {
            hash: txn,
        });

        getFrontendLogger().log(`Withdraw DDX with txhash ${receipt.transactionHash} -> ${receipt}`);
        return { txHash: receipt.transactionHash, blockNumber: parseInt(receipt.blockNumber.toString()) };
    } catch (error: any) {
        throw new Error(
            `System malfunction: Could not read contract for function withdrawDDX. ${getErrorMessage(error)}`,
        );
    }
}

export async function getLatestCheckpointFromContract(
    checkpointContractAddress: Address,
    checkpointContractAbi: Abi,
): Promise<number> {
    try {
        const [, , , epochId]: any = await readContract(config, {
            address: checkpointContractAddress,
            abi: checkpointContractAbi,
            functionName: 'getLatestCheckpoint',
            args: [],
        });
        return epochId !== undefined ? parseInt(epochId.toString()) : 0;
    } catch (error: any) {
        throw new Error(
            `System malfunction: Could not read contract for function getLatestCheckpoint. ${getErrorMessage(error)}`,
        );
    }
}

export async function getCheckpointInfo(
    checkpointContractAddress: Address,
    checkpointContractAbi: Abi,
): Promise<[BigNumber, BigNumber]> {
    try {
        const [consensusThreshold, quorum]: any = await readContract(config, {
            address: checkpointContractAddress,
            abi: checkpointContractAbi,
            functionName: 'getCheckpointInfo',
            args: [],
        });
        return [new BigNumber(consensusThreshold.toString()), new BigNumber(quorum.toString())];
    } catch (error: any) {
        throw new Error(
            `System malfunction: Could not read contract for function getCheckpointInfo. ${getErrorMessage(error)}`,
        );
    }
}

export async function getValidSignersCount(
    custodianContractAddress: Address,
    custodianContractAbi: Abi,
): Promise<BigNumber> {
    try {
        const signersCount: any = await readContract(config, {
            address: custodianContractAddress,
            abi: custodianContractAbi,
            functionName: 'getValidSignersCount',
            args: [DDX_APPLICATION_ID],
        });
        return new BigNumber(signersCount.toString());
    } catch (error: any) {
        throw new Error(
            `System malfunction: Could not read contract for function getValidSignerCount. ${getErrorMessage(error)}`,
        );
    }
}

export async function submitMajorityCheckpoint(
    checkpointContractAddress: Address,
    checkpointContractAbi: Abi,
    majorityCheckpointSubmission: {
        checkpointData: { blockNumber: string; blockHash: string; stateRoot: string; transactionRoot: string };
        signatures: string[];
    },
    epochId: BigNumber,
): Promise<{ txHash: string } | null> {
    try {
        const txn = await writeContract(config, {
            address: checkpointContractAddress,
            abi: checkpointContractAbi,
            functionName: 'checkpoint',
            args: [majorityCheckpointSubmission, [], epochId.toString()],
        });

        const receipt = await waitForTransactionReceipt(config, {
            hash: txn,
        });

        getFrontendLogger().log(`Checkpoint with tx hash ${receipt.transactionHash} -> `);
        return { txHash: receipt.transactionHash };
    } catch (error: any) {
        throw new Error(
            `System malfunction: Could not read contract for function checkpoint. ${getErrorMessage(error)}`,
        );
    }
}

export async function getUnprocessedWithdrawals(
    collateralContractAddress: Address,
    collateralContractAbi: Abi,
    strategyIdHash: string,
    traderAddress: Address,
    tokenAddress: Address,
): Promise<BigNumber> {
    try {
        if (traderAddress === undefined || strategyIdHash === undefined || tokenAddress === undefined) {
            throw new Error(
                `Parameters passed to getUnprocessedWithdrawals contract call are undefined ${{
                    collateralContractAddress,
                    strategyIdHash,
                    traderAddress,
                    tokenAddress,
                }}`,
            );
        }
        const result: any = await readContract(config, {
            address: collateralContractAddress,
            abi: collateralContractAbi,
            functionName: 'getUnprocessedWithdrawals',
            args: [traderAddress, strategyIdHash, tokenAddress],
        });
        return new BigNumber(result.toString());
    } catch (error: any) {
        throw new Error(
            `System malfunction: Could not read contract for function getUnprocessedWithdrawals. ${getErrorMessage(
                error,
            )}`,
        );
    }
}

export async function getUnprocessedDDXWithdrawals(
    stakeContractAddress: Address,
    stakeContractAbi: Abi,
    traderAddress: Address,
): Promise<BigNumber> {
    try {
        if (traderAddress === undefined) {
            throw new Error(
                `Parameters passed to getUnprocessedDDXWithdrawals contract call are undefined ${{
                    stakeContractAddress,
                    traderAddress,
                }}`,
            );
        }
        const result: any = await readContract(config, {
            address: stakeContractAddress,
            abi: stakeContractAbi,
            functionName: 'getUnprocessedDDXWithdrawals',
            args: [traderAddress],
        });
        return new BigNumber(result.toString());
    } catch (error: any) {
        throw new Error(
            `System malfunction: Could not read contract for function getUnprocessedDDXWithdrawals. ${getErrorMessage(
                error,
            )}`,
        );
    }
}

export async function getProcessedWithdrawals(
    collateralContractAddress: Address,
    collateralContractAbi: Abi,
    strategyIdHash: string,
    traderAddress: Address,
    tokenAddress: Address,
    blockNumber: BigNumber,
): Promise<BigNumber> {
    try {
        if (
            traderAddress === undefined ||
            strategyIdHash === undefined ||
            tokenAddress === undefined ||
            blockNumber === undefined
        ) {
            throw new Error(
                `Parameters passed to getProcessedWithdrawals contract call are undefined ${{
                    collateralContractAddress,
                    strategyIdHash,
                    traderAddress,
                    tokenAddress,
                    blockNumber,
                }}`,
            );
        }
        const result: any = await readContract(config, {
            address: collateralContractAddress,
            abi: collateralContractAbi,
            functionName: 'getProcessedWithdrawals',
            args: [traderAddress, strategyIdHash, tokenAddress, blockNumber.toString()],
        });
        return new BigNumber(result.toString());
    } catch (error: any) {
        throw new Error(
            `System malfunction: Could not read contract for function getProcessedWithdrawals. ${getErrorMessage(
                error,
            )}`,
        );
    }
}

export async function getProcessedDDXWithdrawals(
    stakeContractAddress: Address,
    stakeContractAbi: Abi,
    traderAddress: Address,
    blockNumber: BigNumber,
): Promise<BigNumber> {
    try {
        if (traderAddress === undefined || blockNumber === undefined) {
            throw new Error(
                `Parameters passed to getProcessedDDXWithdrawals contract call are undefined ${{
                    stakeContractAddress,
                    traderAddress,
                    blockNumber,
                }}`,
            );
        }
        const result: any = await readContract(config, {
            address: stakeContractAddress,
            abi: stakeContractAbi,
            functionName: 'getProcessedDDXWithdrawals',
            args: [traderAddress, blockNumber.toString()],
        });
        return new BigNumber(result.toString());
    } catch (error: any) {
        throw new Error(
            `System malfunction: Could not read contract for function getProcessedDDXWithdrawals. ${getErrorMessage(
                error,
            )}`,
        );
    }
}

export async function getMaximumWithdrawal(
    collateralContractAddress: Address,
    collateralContractAbi: Abi,
    tokenAddress: Address,
): Promise<MaximumWithdrawal> {
    try {
        if (tokenAddress === undefined) {
            throw new Error(
                `Parameters passed to getMaximumWithdrawal contract call are undefined ${{
                    collateralContractAddress,
                    tokenAddress,
                }}`,
            );
        }
        const [maximumWithdrawalAmount, blocksRemaining]: any = await readContract(config, {
            address: collateralContractAddress,
            abi: collateralContractAbi,
            functionName: 'getMaximumWithdrawal',
            args: [tokenAddress],
        });
        return {
            maximumWithdrawalAmount: new BigNumber(maximumWithdrawalAmount.toString()),
            blocksRemaining: new BigNumber(blocksRemaining.toString()),
        };
    } catch (error: any) {
        throw new Error(
            `System malfunction: Could not read contract for function getMaximumWithdrawal. ${getErrorMessage(error)}`,
        );
    }
}

export function subscribeContractCheckpoints(
    checkpointContractAddress: Address,
    checkpointContractAbi: Abi,
    callback: (value: number) => void,
): () => void {
    try {
        const unwatch = watchContractEvent(config, {
            address: checkpointContractAddress,
            abi: checkpointContractAbi,
            eventName: 'Checkpointed',
            onLogs(logs: any) {
                if (logs.length != 1) {
                    getFrontendLogger().logError('Unexpected event log for handling Checkpointed Event');
                }
                const value =
                    logs[0] && logs[0].args && logs[0].args.epochId && parseInt(logs[0].args.epochId.toString());
                callback(value);
            },
        });

        return unwatch;
    } catch (error: any) {
        throw new Error(
            `System malfunction: Could not read contract for function Checkpointed. ${getErrorMessage(error)}`,
        );
    }
}

export async function updateTokenBalance(
    ethAccount: Address,
    tokenAddress: Address,
    tokenAbi: Abi,
    token: Token,
): Promise<BigNumber> {
    try {
        const balance: any = await readContract(config, {
            address: tokenAddress,
            abi: tokenAbi,
            functionName: 'balanceOf',
            args: [ethAccount],
        });

        const balanceAmount = parseFloat(utils.formatUnits(balance, token.decimals)) || new BigNumber(0);
        return new BigNumber(balanceAmount);
    } catch (error: any) {
        throw new Error(
            `System malfunction: Could not read contract for function balanceOf. ${getErrorMessage(error)}`,
        );
    }
}

export async function getCollateralTokens(
    usdcAddress: Address,
    ddxAddress: Address,
    derivadexAddress: Address,
    ethAddress: Address,
    tokensMeta: TokenMetaData[],
): Promise<{ usdcToken: TokenBalance; ddxToken: TokenBalance }> {
    const tokens: Token[] = mapTokensMetadataToTokens(tokensMeta || []);
    const usdcToken: TokenBalance = await readTokenInfo(
        ethAddress,
        usdcAddress,
        ERC20ContractAbi,
        derivadexAddress,
        getTokenForSymbol('usdc', tokens),
    );
    const ddxToken = await readTokenInfo(
        ethAddress,
        ddxAddress,
        ERC20ContractAbi,
        derivadexAddress,
        getTokenForSymbol('ddx', tokens),
    );
    return { usdcToken, ddxToken };
}

export async function signTypedMessage(data: EIP712TypedDataEthers): Promise<string> {
    return await signTypedData(config, {
        primaryType: data.primaryType,
        domain: data.domain,
        types: data.types,
        message: data.message,
    });
}

async function getDepositSignature(
    traderAddress: string,
    kycConfig: OperatorConfig,
    chainId: number,
    deployment: string,
    verifyingContractAddress: string,
): Promise<{ signature: string; expiryBlock: number }> {
    // query frontend-api for enrollment status and record id
    const kycStatusRequest = constructKYCStatusRequest(
        `${initialRuntimeConfig.REST_API_URL || `${location}v2/rest`}`,
        traderAddress,
    );
    const KycStatusResponse = await fetch(kycStatusRequest);
    const kycStatusData: { success: boolean; recordId: string } = await KycStatusResponse.json();
    getFrontendLogger().log('kyc status data', kycStatusData);

    // get the kyc auth
    const kycAuthRequest = constructKYCAuthRequest(initialRuntimeConfig.KYC_API_URL, traderAddress);
    try {
        const kycAuthResponse = await fetch(kycAuthRequest);
        if (kycAuthResponse.ok) {
            const kycAuthData: { kycAuth: { expiryBlock: number }; signature: string } = await kycAuthResponse.json();
            // trusted kyc service has a record for this trader, so just return the signature and block information
            if (kycAuthData.signature && kycAuthData.signature !== '') {
                return { signature: kycAuthData.signature, expiryBlock: kycAuthData.kycAuth.expiryBlock };
            }
        }
    } catch (error: any) {
        getFrontendLogger().log('kyc auth request hit an error', getErrorMessage(error));
    }

    // otherwise, we need to update the enrollment status of the trader with the kyc service
    // prompt user to sign kyc request
    const kycRequest: KycRequest = {
        hostname: 'kyc.blockpass.org',
        headers: '',
        id: kycStatusData.recordId !== '' ? kycStatusData.recordId : traderAddress,
        signature: '',
    };
    const kycRequestTypedData = createKycRequestTypedData(kycRequest, chainId, deployment, verifyingContractAddress);
    const typedDataForEthers = transformTypedDataForEthers(kycRequestTypedData);
    getFrontendLogger().log('kyc typed data for ethers', typedDataForEthers);

    const signature = await signTypedMessage(typedDataForEthers);
    kycRequest.signature = signature;

    const postKycUpdateResponseData = await postKYCUpdateRequest(
        initialRuntimeConfig.KYC_API_URL,
        encryptIntent(kycConfig.encryptionKey, kycRequest, true) as Uint8Array,
    );

    return {
        signature: postKycUpdateResponseData.signature,
        expiryBlock: postKycUpdateResponseData.kycAuth.expiryBlock,
    };
}

export async function fetchBlockNumber(): Promise<number> {
    try {
        const blockNumber = await getBlockNumber(config);
        return parseInt(blockNumber.toString());
    } catch (error: any) {
        throw new Error(`System malfunction: Could not fetch block number. ${getErrorMessage(error)}`);
    }
}

export function getAddress(address: string): Address {
    return address as Address;
}
