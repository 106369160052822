import { BigNumber } from '@0x/utils';
import { OrderSide } from '@derivadex/types';
import { convertTickSize, orderSizeAfterLeverageOrder } from '@derivadex/utils';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useStore } from 'react-redux';
import { getMarkPricesAsList, getSelectedMarket } from 'store/market/selectors';
import { AppState } from 'store/slices';
import { getPositionsAsList, getSelectedStrategyAvailableCollateral } from 'store/strategy/selectors';

export function useOrderAmountUpdate(
    orderSide: OrderSide,
    setAmount: Dispatch<SetStateAction<string>>,
    price: string,
    leverage: number,
    triggerUpdate: boolean,
    setTriggerUpdate: Dispatch<SetStateAction<boolean>>,
) {
    const store = useStore();
    const state = store.getState() as AppState;

    useEffect(() => {
        if (triggerUpdate) {
            const currentStrategyAvailableCollateral = getSelectedStrategyAvailableCollateral(state);
            const market = getSelectedMarket(state);
            const prices = getMarkPricesAsList(state);
            const positions = getPositionsAsList(state);
            const selectedMarketPrice = prices.find((it) => market?.symbol === it.symbol);
            const amountTickSize = convertTickSize(market?.minOrderSize.toNumber() || 0);
            const amount = orderSizeAfterLeverageOrder(
                positions,
                new BigNumber(currentStrategyAvailableCollateral || 0).toNumber(),
                prices,
                orderSide,
                leverage,
                new BigNumber(price === '' ? selectedMarketPrice?.price.toString() || 0 : price).toNumber(),
                market?.symbol || 'ETHP',
                amountTickSize,
            );
            setTriggerUpdate(false);
            setAmount(new BigNumber(amount).toString());
        }
    }, [triggerUpdate]);
}
