import { BigNumber } from '@0x/utils';
import { Skeleton, Stat, StatLabel, StatNumber, Text } from '@chakra-ui/react';
import { convertTickSize } from '@derivadex/types';
import TooltipWrapper from 'components/Tooltip/TooltipWrapper';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import {
    getSelectedMarketTickerClose,
    getSelectedMarketTickerOpen,
    getSelectedMarketTickSize,
} from 'store/market/selectors';

import { IStatsItem } from './Stats';

export default function TickerChange({
    id,
    isDesktopView,
    width,
}: {
    id: number;
    isDesktopView: boolean;
    width: number | undefined;
}) {
    const openValue = useSelector(getSelectedMarketTickerOpen);
    const closeValue = useSelector(getSelectedMarketTickerClose);
    const tickSize = useSelector(getSelectedMarketTickSize);
    const isNegative = closeValue?.isLessThan(openValue || 0);
    const item: IStatsItem = {
        header: t('24HChange'),
        value: new BigNumber(0),
        visibleWidthOnDesktop: 900,
        thousandSeparator: true,
        decimalScale: 2,
    };
    return (
        <Stat
            key={id}
            whiteSpace="nowrap"
            display={isDesktopView && width && width <= item.visibleWidthOnDesktop ? 'none' : 'block'}
        >
            {isDesktopView ? (
                <StatLabel fontSize="0.75rem" color="gray.400" fontWeight="600">
                    <TooltipWrapper label={item.headerTooltip}>{item.header}</TooltipWrapper>
                </StatLabel>
            ) : (
                <StatLabel fontSize="xs" color="rgb(234, 236, 239)" fontWeight="700">
                    <TooltipWrapper label={item.headerTooltip}>{item.header}</TooltipWrapper>
                </StatLabel>
            )}
            <Skeleton isLoaded={!!item.value} minW="4rem">
                <TooltipWrapper
                    hasArrow
                    label={item.valueTooltip}
                    aria-label={typeof item.valueTooltip === 'string' ? item.valueTooltip : undefined}
                >
                    {isDesktopView ? (
                        <StatNumber
                            fontSize="0.85rem"
                            fontWeight="500"
                            fontFamily="HelveticaNeue"
                            cursor={item.valueTooltip ? 'help' : 'default'}
                            sx={{
                                fontFeatureSettings: 'tnum',
                                fontVariantNumeric: 'tabular-nums',
                            }}
                        >
                            <Text color={isNegative ? '#f04351' : '#70c6a3'}>
                                {`${closeValue
                                    ?.minus(openValue || 0)
                                    .abs()
                                    .toFormat(convertTickSize(tickSize?.toNumber() || 0))} (${
                                    isNegative ? '-' : '+'
                                }${new BigNumber(closeValue || 0)
                                    .minus(openValue || 0)
                                    .dividedBy(openValue || 1)
                                    .multipliedBy(100)
                                    .abs()
                                    .toFixed(2)}%)`}
                            </Text>
                        </StatNumber>
                    ) : (
                        <StatNumber
                            fontSize="xs"
                            fontWeight="semibold"
                            fontFamily="HelveticaNeue"
                            sx={{
                                fontFeatureSettings: 'tnum',
                                fontVariantNumeric: 'tabular-nums',
                            }}
                        >
                            <Text color={isNegative ? '#f04351' : '#70c6a3'}>
                                {`${closeValue
                                    ?.minus(openValue || 0)
                                    .abs()
                                    .toFormat(convertTickSize(tickSize?.toNumber() || 0))}`}
                            </Text>
                        </StatNumber>
                    )}
                </TooltipWrapper>
            </Skeleton>
        </Stat>
    );
}
