import { VStack } from '@chakra-ui/react';
import { OrderSide, OrderType } from '@derivadex/types';
import { getFrontendLogger } from '@derivadex/utils';
import { BigNumber } from 'bignumber.js';
import Checkboxes from 'components/Marketplace/BuySell/Tabs/Checkboxes';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedStrategyMaxLeverage } from 'store/strategy/selectors';
import { useAccount } from 'wagmi';

import OrderDetails from '../OrderDetails';
import AmountInput from './AmountInput';
import ConfirmOrderButton from './ConfirmOrderButton';
import LeverageSlider from './LeverageSlider';
import PercentageButtons from './PercentageButtons';
import PriceInput from './PriceInput';

export enum Intent {
    NONE,
    DANGER,
}

export default function OrderForm({ orderSide, orderType }: { orderSide: OrderSide; orderType: OrderType }) {
    const [makerAmount, setMakerAmount] = useState('');
    const [price, setPrice] = useState('');
    const [leverage, setLeverage] = useState(0);
    const [leverageState, setLeverageState] = useState<Intent | null>(null);
    const [userAlreadyTouched, setUserAlreadyTouched] = useState(false);
    const [checkboxes, setCheckboxes] = useState({
        postOnly: false,
    });

    const { isConnected } = useAccount();

    const currentStrategyMaxLeverage = useSelector(getSelectedStrategyMaxLeverage);

    getFrontendLogger().log('order form re-render');
    return (
        <>
            <VStack spacing={4} align="stretch">
                <AmountInput
                    orderSide={orderSide}
                    price={price}
                    amount={makerAmount}
                    setAmount={setMakerAmount}
                    setLeverage={setLeverage}
                    setLeverageState={setLeverageState}
                    setUserAlreadyTouched={setUserAlreadyTouched}
                    maxLeverage={new BigNumber(currentStrategyMaxLeverage || 0).toNumber()}
                />

                <PriceInput
                    orderType={orderType}
                    orderSide={orderSide}
                    price={price}
                    setPrice={setPrice}
                    amount={makerAmount}
                    setLeverage={setLeverage}
                    setLeverageState={setLeverageState}
                    userAlreadyTouched={userAlreadyTouched}
                    setUserAlreadyTouched={setUserAlreadyTouched}
                    maxLeverage={new BigNumber(currentStrategyMaxLeverage || 0).toNumber()}
                />

                <LeverageSlider
                    isConnected={isConnected}
                    orderSide={orderSide}
                    leverage={leverage}
                    setLeverage={setLeverage}
                    leverageState={leverageState}
                    setLeverageState={setLeverageState}
                    maxLeverage={new BigNumber(currentStrategyMaxLeverage || 0).toNumber()}
                    setAmount={setMakerAmount}
                    price={price}
                />

                <PercentageButtons
                    isConnected={isConnected}
                    maxLeverage={new BigNumber(currentStrategyMaxLeverage || 0).toNumber()}
                    side={orderSide}
                    price={price}
                    setAmount={setMakerAmount}
                    setLeverage={setLeverage}
                    setLeverageState={setLeverageState}
                />

                <Checkboxes orderType={orderType} checkboxes={checkboxes} setCheckboxes={setCheckboxes} />

                <OrderDetails
                    orderType={orderType}
                    orderSide={orderSide}
                    orderPrice={new BigNumber(price)}
                    orderAmount={new BigNumber(makerAmount)}
                    orderLeverage={leverage}
                    currentStrategyMaxLeverage={3}
                />
                <ConfirmOrderButton
                    isConnected={isConnected}
                    orderSide={orderSide}
                    orderType={orderType}
                    leverageState={leverageState}
                    amount={makerAmount}
                    price={price}
                    setUserAlreadyTouched={setUserAlreadyTouched}
                    setAmount={setMakerAmount}
                    setPrice={setPrice}
                    setLeverage={setLeverage}
                    checkboxes={checkboxes}
                />
            </VStack>
        </>
    );
}
