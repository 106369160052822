import { DownloadIcon } from '@chakra-ui/icons';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { useScreenSize } from 'hooks/useScreenSize';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { buildUrl, PortfolioTableType } from 'store/requestUtils';
import { getSelectedStrategy } from 'store/strategy/selectors';
import { getEthAddress } from 'store/web3/selectors';

export default function TableHeader({ portfolioTable }: { portfolioTable: PortfolioTableType }) {
    const isDesktopView = useScreenSize();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const traderAddress = useSelector(getEthAddress);
    const strategy = useSelector(getSelectedStrategy);

    const url = (portfolioTable: PortfolioTableType) => {
        if (portfolioTable === PortfolioTableType.DepositsAndWithdrawals) {
            return buildUrl(
                '/stats/api/v1',
                { account: traderAddress!, strategy: strategy!.strategy },
                'strategy_updates/export',
                { kind: ['0', '1', '2'], order: 'desc' },
            );
        } else if (portfolioTable === PortfolioTableType.FundingHistory) {
            return buildUrl(
                '/stats/api/v1',
                { account: traderAddress!, strategy: strategy!.strategy },
                'strategy_updates/export',
                { kind: 3, order: 'desc' },
            );
        } else if (portfolioTable === PortfolioTableType.TradeMiningRewards) {
            return buildUrl('/stats/api/v1', { account: traderAddress! }, 'trader_updates/export', {
                kind: 3,
                order: 'desc',
            });
        } else return '';
    };

    return (
        <Box mb="0.5rem">
            <Flex>
                {' '}
                <a href={url(portfolioTable)} target="_blank" style={{ pointerEvents: 'inherit' }}>
                    <Tooltip label={'Export as csv'} fontSize="1.5vh" placement="right">
                        <DownloadIcon />
                    </Tooltip>
                </a>
            </Flex>
        </Box>
    );
}
