import { Box, Circle, Divider, Flex, HStack, Link, Text, Tooltip } from '@chakra-ui/react';
import { useScreenSize } from 'hooks/useScreenSize';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FaDiscord, FaTwitter } from 'react-icons/fa';
import { FiArrowUpRight } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { isNode0Healthy } from 'store/ui/selectors';
import { getIsFundingCountdownLocked } from 'store/web3/selectors';

export default function MarketplaceFooter() {
    const isFundingCountdownLocked = useSelector(getIsFundingCountdownLocked);
    const timeRef = useRef<HTMLParagraphElement | null>(null);
    const { t } = useTranslation();
    const isNetworkStatusHealthy = useSelector(isNode0Healthy);
    const isDesktopView = useScreenSize();

    useEffect(() => {
        const interval = setInterval(() => {
            if (timeRef.current) {
                timeRef.current.textContent = `${getUTCTime()}\u00A0UTC`;
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    function getUTCTime() {
        const now = new Date();
        return now.toISOString().slice(11, 19);
    }
    return (
        <Flex
            justifyContent="space-between"
            direction="row"
            align="center"
            fontSize="xs"
            mx={isDesktopView ? '0.1vw' : ''}
            mt={isDesktopView ? '0.1vh' : ''}
        >
            {isDesktopView ? (
                <>
                    <HStack spacing={4}>
                        <Link href="/stats/network" target="_blank">
                            <Flex
                                _hover={{
                                    cursor: 'pointer',
                                    '.chakra-text': { color: 'white', transition: 'all 250ms ease-out' },
                                }}
                            >
                                <Circle
                                    size="0.35rem"
                                    bg={isNetworkStatusHealthy && !isFundingCountdownLocked ? 'green.500' : 'red'}
                                    marginRight="0.5rem"
                                    boxShadow="0 0 5px #00ff00, 0 0 10px #00ff00, 0 0 15px #00ff00, 0 0 20px #00ff00"
                                    mt="0.45rem"
                                    ml="1rem"
                                    data-cy={
                                        isNetworkStatusHealthy && !isFundingCountdownLocked
                                            ? 'network-status-good'
                                            : 'network-status-error'
                                    }
                                />
                                <Tooltip
                                    label={t('networkError')}
                                    isDisabled={isNetworkStatusHealthy && !isFundingCountdownLocked}
                                >
                                    <Text whiteSpace="nowrap" marginLeft={'0.5rem'} fontWeight="500" color="text.100">
                                        {isNetworkStatusHealthy && !isFundingCountdownLocked
                                            ? 'Systems Operational'
                                            : 'Network Error'}
                                    </Text>
                                </Tooltip>
                                <Box ml="0.25rem" mt="0.25rem" color="text.100">
                                    <FiArrowUpRight size={'0.85rem'} />
                                </Box>
                            </Flex>
                        </Link>
                        <Divider orientation="vertical" height="1rem" marginLeft="1rem" marginRight="0.5rem" />
                        <Text color="white" fontWeight="500" ref={timeRef} marginLeft="0.5rem">
                            {`${getUTCTime()}\u00A0UTC`}
                        </Text>
                    </HStack>
                    <HStack spacing={4} pr="1rem">
                        <Link href="https://discord.gg/CaqdRvA" target="_blank">
                            <FaDiscord size="1rem" />
                        </Link>
                        <Link href="https://twitter.com/DDX_Official" target="_blank" marginLeft="0.5rem">
                            <FaTwitter size="1rem" />
                        </Link>
                    </HStack>
                </>
            ) : (
                <>
                    <Link href="/stats/network" target="_blank">
                        <Flex
                            _hover={{
                                cursor: 'pointer',
                                '.chakra-text': { color: 'white', transition: 'all 250ms ease-out' },
                            }}
                        >
                            <Circle
                                size="0.35rem"
                                bg={isNetworkStatusHealthy && !isFundingCountdownLocked ? 'green.500' : 'red'}
                                marginRight="0.5rem"
                                boxShadow="0 0 5px #00ff00, 0 0 10px #00ff00, 0 0 15px #00ff00, 0 0 20px #00ff00"
                                mt="0.45rem"
                                ml="0.25rem"
                                data-cy={
                                    isNetworkStatusHealthy && !isFundingCountdownLocked
                                        ? 'network-status-good'
                                        : 'network-status-error'
                                }
                            />
                            <Tooltip
                                label={t('networkError')}
                                isDisabled={isNetworkStatusHealthy && !isFundingCountdownLocked}
                            >
                                <Text whiteSpace="nowrap" marginLeft={'0.25rem'} fontWeight="500" color="text.100">
                                    {isNetworkStatusHealthy && !isFundingCountdownLocked
                                        ? 'Systems Operational'
                                        : 'Network Error'}
                                </Text>
                            </Tooltip>
                            <Box ml="0.25rem" mt="0.25rem" color="text.100">
                                <FiArrowUpRight size={'0.85rem'} />
                            </Box>
                        </Flex>
                    </Link>
                    <Text color="white" fontWeight="500" ref={timeRef}>
                        {`${getUTCTime()}\u00A0UTC`}
                    </Text>
                </>
            )}
        </Flex>
    );
}
