import { BigNumber } from '@0x/utils';
import { Skeleton, Stat, StatLabel, StatNumber, Text } from '@chakra-ui/react';
import { MarketKind } from '@derivadex/types';
import TooltipWrapper from 'components/Tooltip/TooltipWrapper';
import { time } from 'console';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getEpochConfig, getRuntimeConfig } from 'store/config/selectors';
import { getSelectedMarket, getSelectedMarketFundingRate } from 'store/market/selectors';
import { getEpoch, getIsFundingCountdownLocked, getTimeValueSinceEpoch } from 'store/web3/selectors';
import { SET_IS_FUNDING_COUNTDOWN_FROZEN } from 'store/web3/slice';
import { fixedExpiryFuturesQuarterlyTimeToDelivery } from 'utils/time_utils';

import { IStatsItem } from './Stats';

export default function FundingCountdown({
    id,
    isDesktopView,
    width,
}: {
    id: number;
    isDesktopView: boolean;
    width: number | undefined;
}) {
    const fundingRate = useSelector(getSelectedMarketFundingRate);
    const epoch = useSelector(getEpoch);
    const timeValueSinceEpoch = useSelector(getTimeValueSinceEpoch);
    const isFundingCountdownLocked = useSelector(getIsFundingCountdownLocked);
    const epochConfig = useSelector(getEpochConfig);
    const runtimeConfig = useSelector(getRuntimeConfig);
    const [rewardPeriodCountdown, setRewardPeriodCountdown] = useState('-');
    const [stopCountdown, setStopCoundown] = useState(false);
    const selectedMarket = useSelector(getSelectedMarket);
    const shouldDisplayFundingCountdown = selectedMarket?.kind !== MarketKind.FixedExpiryFuture;
    const dispatch = useDispatch();

    useEffect(() => {
        let interval: NodeJS.Timer;
        if (epochConfig && epoch && timeValueSinceEpoch !== undefined) {
            setStopCoundown(false);
            let currentTime = timeValueSinceEpoch;
            updateCountdown(currentTime);
            interval = setInterval(() => {
                updateCountdown(++currentTime);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [epochConfig, epoch, timeValueSinceEpoch]);

    function updateCountdown(currentTime: number) {
        if (epochConfig && epoch && timeValueSinceEpoch !== undefined) {
            const TMM = epochConfig.fundingEpochMultiplier;
            const EPOCH_SIZE = epochConfig.epochSize;
            const CLOCK_TICK_SIZE = parseInt(runtimeConfig.CLOCK_TICK_SIZE);
            const countdown = (TMM - (epoch % TMM)) * EPOCH_SIZE;
            if (currentTime <= EPOCH_SIZE) {
                setRewardPeriodCountdown(formatCountdown(countdown * CLOCK_TICK_SIZE, currentTime * 1000));
                if (isFundingCountdownLocked) {
                    dispatch(SET_IS_FUNDING_COUNTDOWN_FROZEN(false));
                }
            } else {
                // If we have not received a new epoch update from the checkpoint_feed_handler within a number of ticks === EPOCH_SIZE
                // then we freeze the coundown clock until there is a new epoch update from Frontend-api.
                // This prevents a false sense of liveness of the exchange when the leader operator node is down.
                // It also prevents the countdown clock from drifting too far away from the operator's sense of time.
                // The countdown clock will always be within EPOCH_SIZE ticks of accuracy of the operator's latest time_value.
                setStopCoundown(true);
                dispatch(SET_IS_FUNDING_COUNTDOWN_FROZEN(true));
            }
        }
    }

    const fundingCountdownValue = () => {
        return (
            shouldDisplayFundingCountdown && (
                <Text>
                    <span style={{ color: fundingRate && fundingRate.value.isNegative() ? '#f04351' : '#70c6a3' }}>
                        {fundingRate?.value.toFixed(4)}
                    </span>{' '}
                    {'/ '}
                    <span style={{ color: stopCountdown ? '#f04351' : '' }}>{rewardPeriodCountdown}</span>
                </Text>
            )
        );
    };

    function formatCountdown(countdown: number, currentTime: number): string {
        const remainingTimeInMillis = countdown - currentTime;
        if (remainingTimeInMillis <= 0) {
            return '00:00:00';
        }

        const hours = Math.floor(remainingTimeInMillis / (60 * 60 * 1000));
        const minutes = Math.floor((remainingTimeInMillis % (60 * 60 * 1000)) / (60 * 1000));
        const seconds = Math.floor((remainingTimeInMillis % (60 * 1000)) / 1000);

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    const item: IStatsItem = {
        header: t('fundingRateCountdown'),
        value: new BigNumber(0),
        visibleWidthOnDesktop: 1200,
        headerTooltip: t('fundingRateCountdownTooltip'),
        thousandSeparator: true,
        decimalScale: 4,
        hide: !shouldDisplayFundingCountdown,
    };

    return shouldDisplayFundingCountdown ? (
        <Stat
            key={id}
            whiteSpace="nowrap"
            display={isDesktopView && width && width <= item.visibleWidthOnDesktop ? 'none' : 'block'}
        >
            {isDesktopView ? (
                <StatLabel fontSize="0.75rem" color="gray.400" fontWeight="600">
                    <TooltipWrapper label={item.headerTooltip}>{item.header}</TooltipWrapper>
                </StatLabel>
            ) : (
                <StatLabel fontSize="xs" color="rgb(234, 236, 239)" fontWeight="600">
                    <TooltipWrapper label={item.headerTooltip}>{item.header}</TooltipWrapper>
                </StatLabel>
            )}
            <Skeleton isLoaded={!!item.value || !!time} minW="4rem">
                <TooltipWrapper
                    hasArrow
                    label={
                        <Trans i18nKey="annualizedFundingRate">
                            Annualized: ({fundingRate ? `${fundingRate.value.multipliedBy(365 * 3).toFixed(2)}%` : '0%'}
                            )
                        </Trans>
                    }
                >
                    {isDesktopView ? (
                        <StatNumber
                            fontSize="0.85rem"
                            fontWeight="500"
                            fontFamily="HelveticaNeue"
                            cursor={'help'}
                            sx={{
                                fontFeatureSettings: 'tnum',
                                fontVariantNumeric: 'tabular-nums',
                            }}
                        >
                            {fundingCountdownValue()}
                        </StatNumber>
                    ) : (
                        <StatNumber
                            fontSize="xs"
                            fontWeight="500"
                            fontFamily="HelveticaNeue"
                            sx={{
                                fontFeatureSettings: 'tnum',
                                fontVariantNumeric: 'tabular-nums',
                            }}
                        >
                            {fundingCountdownValue()}
                        </StatNumber>
                    )}
                </TooltipWrapper>
            </Skeleton>
        </Stat>
    ) : (
        <></>
    );
}
