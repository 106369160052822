import {
    Button,
    ButtonGroup,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerOverlay,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import BuySellPanel from './BuySellPanel';

enum BuySellTab {
    Market,
    Limit,
}

export default function MobileBuySell() {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState(BuySellTab.Market);
    const [isActive, setIsActive] = useState(false);

    const open = (tab: BuySellTab) => {
        setSelectedTab(tab);
        setIsActive(true);
    };

    const close = () => {
        setIsActive(false);
    };

    return (
        <>
            <ButtonGroup size={'sm'} width="100%" py="0.5rem">
                <Button
                    id="deposit-button"
                    data-cy="deposit-button"
                    fontSize={'xs'}
                    color="white"
                    colorScheme={'brand.green'}
                    onClick={() => open(BuySellTab.Market)}
                    width="full"
                >
                    {t('buy')}
                </Button>
                <Button
                    id="withdraw-button"
                    fontSize={'xs'}
                    color="white"
                    colorScheme={'brand.red'}
                    onClick={() => open(BuySellTab.Limit)}
                    width="full"
                >
                    {t('sell')}
                </Button>
            </ButtonGroup>
            <Drawer isOpen={isActive} placement={'bottom'} onClose={() => close()}>
                <DrawerOverlay>
                    <DrawerContent bgColor="card.100" id="drawer">
                        <DrawerCloseButton />
                        <DrawerBody>
                            <BuySellPanel />
                        </DrawerBody>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </>
    );
}
