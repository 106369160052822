import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    header: string | JSX.Element;
    children: JSX.Element;
    footer?: string | JSX.Element;
    color?: string;
    bgColor?: string;
    disableClose?: boolean;
    isCentered?: boolean;
    size?: string;
}

export default function Dialog({
    isOpen,
    onClose,
    header,
    children,
    footer,
    color,
    bgColor,
    disableClose = false,
    isCentered = true,
    size,
}: IProps) {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered={isCentered}
            closeOnEsc={!disableClose}
            closeOnOverlayClick={!disableClose}
            size={size}
        >
            <ModalOverlay />
            <ModalContent bgColor="card.100" color={color}>
                <ModalHeader>{header}</ModalHeader>
                {!disableClose && <ModalCloseButton />}

                <ModalBody bgColor={bgColor}>{children}</ModalBody>
                {footer && <ModalFooter>{footer}</ModalFooter>}
            </ModalContent>
        </Modal>
    );
}
