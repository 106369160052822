import { BigNumber } from '@0x/utils';
import { convertTickSize } from '@derivadex/utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSelectedMarket, getSelectedMarketMarkPrice } from 'store/market/selectors';

import CommonStatsItem from './CommonStatsItem';
import { IStatsItem } from './Stats';

export default function MarkPrice({
    id,
    isDesktopView,
    width,
}: {
    id: number;
    isDesktopView: boolean;
    width: number | undefined;
}) {
    const markPrice = useSelector(getSelectedMarketMarkPrice);
    const selectedMarket = useSelector(getSelectedMarket);
    const { t } = useTranslation();
    const item: IStatsItem = {
        header: t('mark'),
        value: markPrice?.price ?? new BigNumber(0),
        visibleWidthOnDesktop: 800,
        headerTooltip: t('markPriceTooltip'),
        thousandSeparator: true,
        decimalScale: selectedMarket ? convertTickSize(selectedMarket.tickSize.toNumber()) : 2,
    };
    return <CommonStatsItem id={id} isDesktopView={isDesktopView} item={item} width={width} />;
}
