import { Badge, Flex, HStack, Text } from '@chakra-ui/react';
import { OrderSide, OrderType, UIPosition } from '@derivadex/types';
import { liquidationPriceAfterOrder } from '@derivadex/utils';
import { BigNumber } from 'bignumber.js';
import TooltipWrapper from 'components/Tooltip/TooltipWrapper';
import { useScreenSize } from 'hooks/useScreenSize';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { getSelectedMarket, getSelectedMarketPrice } from 'store/market/selectors';
import { getProfileDetails } from 'store/profile/selectors';
import { AppState } from 'store/slices';
import { getSelectedMarketPosition, getSelectedStrategyAvailableCollateral } from 'store/strategy/selectors';

// Adding also mark price, current position & current strategy
// as props, or should i do useSelector instead?
interface IProps {
    orderType: OrderType;
    orderPrice: BigNumber;
    orderAmount: BigNumber;
    orderSide: OrderSide;
    orderLeverage: number;
    currentStrategyMaxLeverage: number;
}

function getEstimatedFee(
    orderType: OrderType,
    orderSize: BigNumber,
    markPrice: BigNumber,
    payFeesInDDX: boolean,
): number | '-' {
    const notional = orderSize.multipliedBy(markPrice);

    if (Number.isNaN(notional.toNumber()) || notional.toNumber() > 10e25) {
        return '-';
    }

    // 0bps maker fee
    if (orderType === OrderType.LimitPostOnly || orderType === OrderType.Limit) {
        return 0;
    }
    // 20bps taker fee, 10bps for ddx fes
    return notional.multipliedBy(payFeesInDDX ? 0.001 : 0.002).toNumber();
}

function getEstimatedLiquidationPrice(
    orderType: OrderType,
    currentPosition: UIPosition | undefined,
    strategyCollateral: number,
    markPrice: BigNumber,
    orderSide: OrderSide,
    orderSize: number,
    maxLeverage: number,
    orderPrice: number,
): number | '-' {
    const liqPrice = liquidationPriceAfterOrder(
        currentPosition,
        strategyCollateral,
        markPrice,
        orderSide,
        orderSize,
        maxLeverage,
        orderPrice,
    );

    //TODO: 10e25 is arbitrary
    if (Number.isNaN(liqPrice.toNumber()) || liqPrice.toNumber() > 10e25) return '-';
    else return liqPrice.toNumber();
}

function getNotional(orderSize: BigNumber, markPrice: BigNumber): number | '-' {
    const notional = orderSize.multipliedBy(markPrice);
    //TODO: 10e25 is arbitrary
    if (Number.isNaN(notional.toNumber()) || notional.toNumber() > 10e25) return '-';
    else return notional.toNumber();
}

function OrderNotional({ orderPrice, orderAmount }: { orderPrice: BigNumber; orderAmount: BigNumber }) {
    const { t } = useTranslation();

    const Memo = memo(() => {
        return (
            <Flex alignItems="center" justifyContent="space-between" fontSize="xs">
                <TooltipWrapper label={t('notionalTooltip')} aria-label={t('notionalTooltip')} hasArrow>
                    <Text cursor="help" color="text.100">
                        {t('notional')}
                    </Text>
                </TooltipWrapper>
                <HStack spacing="0.5rem">
                    <Text fontWeight="600">
                        <NumericFormat
                            value={getNotional(orderAmount, orderPrice)}
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                        />
                    </Text>
                    <Badge minW="2rem" textAlign="center">
                        USD
                    </Badge>
                </HStack>
            </Flex>
        );
    });
    return <Memo />;
}

function FeeEstimation({
    orderType,
    orderPrice,
    orderAmount,
}: {
    orderType: OrderType;
    orderPrice: BigNumber;
    orderAmount: BigNumber;
}) {
    const { t } = useTranslation();
    const traderState = useSelector(getProfileDetails);

    const FeeComponent = memo(() => {
        return (
            <Flex alignItems="center" justifyContent="space-between" fontSize="xs">
                <TooltipWrapper label={t('feeEstimationTooltip')} aria-label={t('feeEstimationTooltip')} hasArrow>
                    <Text color="text.100" cursor="help">
                        {t('feeEstimation')}
                    </Text>
                </TooltipWrapper>
                <HStack spacing="0.5rem">
                    <Text fontWeight="600">
                        <NumericFormat
                            value={getEstimatedFee(orderType, orderAmount, orderPrice, traderState.payFeesInDDX)}
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                        />
                    </Text>
                    <Badge minW="2rem" textAlign="center">
                        USD
                    </Badge>
                </HStack>
            </Flex>
        );
    });

    return <FeeComponent />;
}

function OrderLiquidation({
    orderType,
    orderSide,
    orderPrice,
    orderAmount,
    currentStrategyMaxLeverage,
}: {
    orderType: OrderType;
    orderSide: OrderSide;
    orderPrice: BigNumber;
    orderAmount: BigNumber;
    currentStrategyMaxLeverage: number;
}) {
    const { t } = useTranslation();
    const market = useSelector(getSelectedMarket);
    const markPrice = useSelector((state: AppState) => getSelectedMarketPrice(state, market?.symbol || ''));
    const currentPosition = useSelector((state: AppState) => getSelectedMarketPosition(state, market?.symbol || ''));
    const currentStrategyAvailableCollateral = useSelector(getSelectedStrategyAvailableCollateral);

    const Memo = memo(() => {
        return (
            <Flex alignItems="center" justifyContent="space-between" fontSize="xs">
                <TooltipWrapper label={t('liquidationPriceTooltip')} aria-label={t('liquidationPriceTooltip')} hasArrow>
                    <Text color="text.100" cursor="help">
                        {t('liquidationPrice')}
                    </Text>
                </TooltipWrapper>
                <HStack spacing="0.5rem">
                    <Text fontWeight="600">
                        <NumericFormat
                            value={getEstimatedLiquidationPrice(
                                orderType,
                                currentPosition,
                                new BigNumber(currentStrategyAvailableCollateral || 0).toNumber(),
                                markPrice?.price || new BigNumber(0),
                                orderSide,
                                orderAmount.toNumber(),
                                currentStrategyMaxLeverage,
                                orderPrice.toNumber(),
                            )}
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                        />
                    </Text>
                    <Badge minW="2rem" textAlign="center">
                        USD
                    </Badge>
                </HStack>
            </Flex>
        );
    });
    return <Memo />;
}

export default function OrderDetails({
    orderType,
    orderPrice,
    orderAmount,
    orderSide,
    currentStrategyMaxLeverage,
}: IProps) {
    const { t } = useTranslation();
    const isDesktopView = useScreenSize();

    const Memo = memo(() => {
        return (
            <Flex flexDirection="column" justifyContent="space-between" fontSize="xs">
                <OrderNotional orderPrice={orderPrice} orderAmount={orderAmount} />
                <FeeEstimation orderType={orderType} orderPrice={orderPrice} orderAmount={orderAmount} />
                <OrderLiquidation
                    orderType={orderType}
                    orderSide={orderSide}
                    orderPrice={orderPrice}
                    orderAmount={orderAmount}
                    currentStrategyMaxLeverage={currentStrategyMaxLeverage}
                />
            </Flex>
        );
    });
    return <Memo />;
}
