import { Button, Text } from '@chakra-ui/react';
import { FiChevronDown } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedStrategyId } from 'store/strategy/selectors';
import { TOGGLE_STRATEGIES_DIALOG } from 'store/ui/slice';

import { useScreenSize } from '../../hooks/useScreenSize';

export default function StrategiesButton({ isConnected }: { isConnected: boolean }) {
    const currentStrategyId = useSelector(getSelectedStrategyId);
    const dispatch = useDispatch();

    const isDesktopView = useScreenSize();

    return (
        <Button
            id="strategy-button"
            onClick={() => dispatch(TOGGLE_STRATEGIES_DIALOG())}
            rightIcon={<FiChevronDown />}
            colorScheme="rhino"
            color="white"
            mt={isDesktopView ? '-0.25rem' : ''}
            mx={isDesktopView ? '1rem' : ''}
            size={isDesktopView ? 'md' : 'sm'}
            fontSize={isDesktopView ? '1rem' : 'xs'}
            backgroundColor={'card.100'}
            _hover={{
                bgColor: 'card.100',
                color: 'text.100',
            }}
            isDisabled={!isConnected}
        >
            <Text alignItems={'left'} fontWeight="600">
                {currentStrategyId || 'main'}
            </Text>
        </Button>
    );
}
