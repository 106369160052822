import { Checkbox, CheckboxGroup, HStack } from '@chakra-ui/react';
import { OrderType } from '@derivadex/types';
import { useScreenSize } from 'hooks/useScreenSize';
import { useTranslation } from 'react-i18next';

interface ICheckbox {
    postOnly: boolean;
}

export default function Checkboxes({
    orderType,
    checkboxes,
    setCheckboxes,
}: {
    orderType: OrderType;
    checkboxes: ICheckbox;
    setCheckboxes: (x: any) => void;
}) {
    const { t } = useTranslation();
    const isDesktopView = useScreenSize();

    return orderType === OrderType.Limit ? (
        <CheckboxGroup colorScheme="lavender" size={isDesktopView ? 'md' : 'xs'}>
            <HStack fontWeight="medium">
                <Checkbox
                    fontSize={isDesktopView ? 'md' : 'xs'}
                    isDisabled={false}
                    isChecked={checkboxes.postOnly}
                    onChange={() => setCheckboxes({ ...checkboxes, postOnly: !checkboxes.postOnly })}
                >
                    {t('postOnly')}
                </Checkbox>
            </HStack>
        </CheckboxGroup>
    ) : (
        <></>
    );
}
