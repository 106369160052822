import { Badge, Flex, FormControl, FormLabel, Input, InputGroup, InputRightElement, Tooltip } from '@chakra-ui/react';
import TooltipWrapper from 'components/Tooltip/TooltipWrapper';
import { useScreenSize } from 'hooks/useScreenSize';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'wagmi';

interface IProps {
    id: string;
    tooltip: string;
    title: string;
    badge: string;
    inputEnforcer: (x: string, y: string) => void;
    value: string;
    cy: string;
}

export default function InputComponent({ id, tooltip, title, badge, inputEnforcer, value, cy }: IProps) {
    const { t } = useTranslation();
    const { isConnected } = useAccount();
    const isDesktopView = useScreenSize();

    return (
        <FormControl id={id} aria-label={t(id)}>
            <Flex justifyContent="space-between">
                <TooltipWrapper placement="right" hasArrow label={tooltip} aria-label={tooltip}>
                    <FormLabel htmlFor={id} color="text.100" fontSize={isDesktopView ? 'md' : 'xs'} cursor="help">
                        {t(id)}
                    </FormLabel>
                </TooltipWrapper>
            </Flex>

            <InputGroup fontSize={isDesktopView ? 'md' : 'xs'}>
                <Input
                    pr="3.5rem"
                    variant="filled"
                    overflow="visible"
                    id={id}
                    data-cy={cy}
                    title={title}
                    type="text"
                    min={0}
                    maxLength={id === 'price' ? 13 : 15}
                    value={value}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        inputEnforcer(e.target.value.replace(/,/g, '.'), id)
                    }
                    inputMode="decimal"
                    pattern="^[0-9]*[.,]?[0-9]*$"
                    placeholder="0.00"
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="false"
                    isDisabled={isConnected ? false : true}
                />
                <InputRightElement
                    children={
                        <Badge mr="1rem" textAlign="center">
                            {badge}
                        </Badge>
                    }
                />
            </InputGroup>
        </FormControl>
    );
}
