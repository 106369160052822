import { Flex, Text } from '@chakra-ui/react';
import { getErrorMessage, getFrontendLogger } from '@derivadex/utils';
import TooltipWrapper from 'components/Tooltip/TooltipWrapper';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    getPreviousClose,
    getSelectedMarketMarkPrice,
    getSelectedMarketTickerClose,
    getSelectedMarketTickSize,
} from 'store/market/selectors';

export default function TickerClose({ isDesktopView }: { isDesktopView: boolean }) {
    const [closeTickerColor, setCloseTickerColor] = useState('');
    const value = useSelector(getSelectedMarketTickerClose);
    const previousValue = useSelector(getPreviousClose);
    const markPrice = useSelector(getSelectedMarketMarkPrice);
    const tickSize = useSelector(getSelectedMarketTickSize);

    useEffect(() => {
        if (value === undefined || markPrice === undefined) {
            return;
        }
        try {
            document.title = `${
                !value.isEqualTo(-1) ? value.toFormat(tickSize?.dp()) : markPrice?.price.toFormat(tickSize?.dp())
            } · ${markPrice.symbol} · DerivaDEX | Exchange`;
        } catch (error: any) {
            getFrontendLogger().logError(getErrorMessage(error));
        }
    }, [value, markPrice]);

    useEffect(() => {
        if (value === undefined || previousValue === undefined) {
            return;
        }

        if (!value.isEqualTo(previousValue ?? 0)) {
            setCloseTickerColor(value.isGreaterThan(previousValue ?? 0) ? '#70c6a3' : '#f04351');
        }
    }, [previousValue, value]);

    return (
        (!value?.isEqualTo(-1) && (
            <TooltipWrapper label={'Last traded price'} fontSize="1.5vh" placement={'left'}>
                <Text
                    fontSize={isDesktopView ? '0.85rem' : ''}
                    fontWeight={isDesktopView ? '600' : 'bold'}
                    color={previousValue !== undefined ? closeTickerColor : ''}
                >
                    ${value?.toFormat(tickSize?.dp() ?? 0)}
                </Text>
            </TooltipWrapper>
        )) || <></>
    );
}
