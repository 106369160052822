import { BigNumber } from '@0x/utils';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { getEpoch } from 'store/web3/selectors';

import CommonStatsItem from './CommonStatsItem';
import { IStatsItem } from './Stats';

export default function Epoch({
    id,
    isDesktopView,
    width,
}: {
    id: number;
    isDesktopView: boolean;
    width: number | undefined;
}) {
    const value = useSelector(getEpoch);
    const item: IStatsItem = {
        header: t('epoch'),
        value: value !== undefined ? new BigNumber(value) : new BigNumber(0),
        visibleWidthOnDesktop: 1300,
        valueTooltip: t('epochTooltip'),
        thousandSeparator: false,
        decimalScale: 0,
    };
    return <CommonStatsItem id={id} isDesktopView={isDesktopView} item={item} width={width} />;
}
