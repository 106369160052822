export const resolutionToIntervalType = (resolution: string) =>
    resolution === '1' ? '1m' : resolution === '15' ? '15m' : resolution === '60' ? '1h' : '1d';

export const fixedExpiryFuturesQuarterlyTimeToDelivery = (quarter: 'H' | 'M' | 'U' | 'Z'): string => {
    const now = new Date();
    let targetYear = now.getUTCFullYear();

    // map each quarter to its end month
    const quarterEndMonths: Record<'H' | 'M' | 'U' | 'Z', number> = {
        H: 2, // March (0-based index)
        M: 5, // June
        U: 8, // September
        Z: 11, // December
    };

    const endMonth = quarterEndMonths[quarter];

    // get the end date of the quarter in the correct year
    let quarterEndDate = new Date(Date.UTC(targetYear, endMonth + 1, 1));
    quarterEndDate.setUTCDate(0); // Last day of the month
    quarterEndDate.setUTCHours(23, 59, 59, 999);

    // if `quarterEndDate` is in the past, move it to the next year
    if (quarterEndDate < now) {
        targetYear += 1;
        quarterEndDate = new Date(Date.UTC(targetYear, endMonth + 1, 1));
        quarterEndDate.setUTCDate(0);
        quarterEndDate.setUTCHours(23, 59, 59, 999);
    }

    const timeDiff = quarterEndDate.getTime() - now.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    if (daysDiff > 2) {
        return `${daysDiff} days`;
    }

    const hoursDiff = Math.ceil(timeDiff / (1000 * 60 * 60));

    if (hoursDiff > 1) {
        return `${hoursDiff} hours`;
    }

    const minutesDiff = Math.ceil(timeDiff / (1000 * 60));
    return `${minutesDiff} minutes`;
};
