import { BigNumber } from '@0x/utils';
import { Skeleton, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import TooltipWrapper from 'components/Tooltip/TooltipWrapper';
import { NumericFormat } from 'react-number-format';

import { IStatsItem } from './Stats';

export default function CommonStatsItem({
    id,
    item,
    isDesktopView,
    width,
}: {
    id: number;
    item: IStatsItem;
    isDesktopView: boolean;
    width: number | undefined;
}) {
    return !item.hide ? (
        <Stat
            key={id}
            whiteSpace="nowrap"
            display={isDesktopView && width && width <= item.visibleWidthOnDesktop ? 'none' : 'block'}
        >
            {isDesktopView ? (
                <StatLabel fontSize="0.75rem" color="gray.400" fontWeight="700">
                    <TooltipWrapper label={item.headerTooltip}>{item.header}</TooltipWrapper>
                </StatLabel>
            ) : (
                <StatLabel fontSize="xs" color="rgb(234, 236, 239)" fontWeight="700">
                    <TooltipWrapper label={item.headerTooltip}>{item.header}</TooltipWrapper>
                </StatLabel>
            )}
            <Skeleton isLoaded={!!item.value}>
                <TooltipWrapper
                    hasArrow
                    label={item.valueTooltip}
                    aria-label={typeof item.valueTooltip === 'string' ? item.valueTooltip : undefined}
                >
                    {isDesktopView ? (
                        <StatNumber
                            fontSize="0.85rem"
                            fontWeight="500"
                            fontFamily="HelveticaNeue"
                            cursor={item.valueTooltip ? 'help' : 'default'}
                            sx={{
                                fontFeatureSettings: 'tnum',
                                fontVariantNumeric: 'tabular-nums',
                            }}
                        >
                            {item.value instanceof BigNumber ? (
                                <NumericFormat
                                    value={item.value && item.value.toNumber() !== -1 ? item.value.toNumber() : '-'}
                                    displayType={'text'}
                                    thousandSeparator={item.thousandSeparator}
                                    fixedDecimalScale
                                    decimalScale={item.decimalScale}
                                />
                            ) : (
                                item.value
                            )}
                        </StatNumber>
                    ) : (
                        <StatNumber
                            fontSize="xs"
                            fontWeight="semibold"
                            fontFamily="HelveticaNeue"
                            sx={{
                                fontFeatureSettings: 'tnum',
                                fontVariantNumeric: 'tabular-nums',
                            }}
                        >
                            {item.value instanceof BigNumber ? (
                                <NumericFormat
                                    value={item.value && item.value.toNumber() !== -1 ? item.value.toNumber() : '-'}
                                    displayType={'text'}
                                    thousandSeparator={item.thousandSeparator}
                                    fixedDecimalScale
                                    decimalScale={item.decimalScale}
                                />
                            ) : (
                                item.value
                            )}
                        </StatNumber>
                    )}
                </TooltipWrapper>
            </Skeleton>
        </Stat>
    ) : (
        <></>
    );
}
