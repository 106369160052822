import { BigNumber } from '@0x/utils';
import {
    Box,
    Button,
    Divider,
    Flex,
    HStack,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    SimpleGrid,
    StatGroup,
} from '@chakra-ui/react';
import MarketsButton from 'components/Markets/MarketsButton';
import StrategiesButton from 'components/Strategies/StrategiesButton';
import { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { getSelectedMarket } from 'store/market/selectors';
import { useAccount } from 'wagmi';

import { useScreenSize } from '../../hooks/useScreenSize';
import { useWindowSize } from '../../hooks/useWindowWidth';
import Epoch from './Epoch';
import FundingCountdown from './FundingCountdown';
import MarkPrice from './MarkPrice';
import OpenInterest from './OpenInterest';
import TickerChange from './TickerChange';
import TickerClose from './TickerClose';
import TickerHigh from './TickerHigh';
import TickerLow from './TickerLow';
import TickerVolume from './TickerVolume';
import TimeToDelivery from './TimeToDelivery';

export interface IStatsItem {
    header: string;
    value?: BigNumber | JSX.Element;
    time?: string | null;
    visibleWidthOnDesktop: number;
    headerTooltip?: string;
    valueTooltip?: string;
    thousandSeparator: boolean;
    decimalScale: number;
    hide?: boolean;
}

export default function Stats() {
    const selectedMarket = useSelector(getSelectedMarket);
    const width = useWindowSize();
    const isDesktopView = useScreenSize();
    const { isConnected } = useAccount();
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    return (
        <>
            {isDesktopView ? (
                <Flex alignSelf="center">
                    <StrategiesButton isConnected={isConnected} />
                    <Divider orientation="vertical" pr="1rem" h="2rem" border={0} alignSelf="center" />
                    <MarketsButton />
                    <Divider orientation="vertical" pr="1rem" h="2rem" border={0} alignSelf="center" />
                    <HStack spacing={6}>
                        <MarkPrice isDesktopView={isDesktopView} id={1} width={width} />
                        <TickerChange isDesktopView={isDesktopView} id={2} width={width} />
                        <TickerHigh isDesktopView={isDesktopView} id={3} width={width} />
                        <TickerLow isDesktopView={isDesktopView} id={4} width={width} />
                        <TickerVolume isDesktopView={isDesktopView} id={5} width={width} />
                        <OpenInterest
                            isDesktopView={isDesktopView}
                            id={6}
                            width={width}
                            symbol={selectedMarket?.symbol || 'ETHP'}
                        />
                        <FundingCountdown isDesktopView={isDesktopView} id={7} width={width} />
                        <TimeToDelivery isDesktopView={isDesktopView} id={8} width={width} />
                        <Epoch isDesktopView={isDesktopView} id={9} width={width} />
                    </HStack>
                </Flex>
            ) : (
                <>
                    <HStack spacing={3}>
                        <Flex justifyContent="space-between" width="100vw">
                            <MarketsButton />
                            <Button
                                id="stats-expand-button"
                                data-cy="stats-expand-button"
                                rightIcon={isExpanded ? <FiChevronUp /> : <FiChevronDown />}
                                onClick={() => setIsExpanded(!isExpanded)}
                                colorScheme="rhino"
                                color="white"
                                mt="-0.25rem"
                                size={isDesktopView ? 'md' : 'sm'}
                                backgroundColor={'card.100'}
                                _hover={{
                                    bgColor: 'card.100',
                                    color: 'text.100',
                                }}
                                border="none"
                                _focus={{
                                    outline: 'none',
                                }}
                            >
                                <Flex alignItems="center">
                                    <TickerClose isDesktopView={isDesktopView} />
                                </Flex>
                            </Button>
                        </Flex>
                    </HStack>
                    <Flex
                        mx="0.5rem"
                        justifyContent="space-between"
                        style={isExpanded ? { display: '' } : { display: 'none' }}
                    >
                        <SimpleGrid columns={2} spacingY={2} spacingX={20}>
                            <MarkPrice isDesktopView={isDesktopView} id={1} width={width} />
                            <TickerVolume isDesktopView={isDesktopView} id={2} width={width} />
                            <OpenInterest
                                isDesktopView={isDesktopView}
                                id={3}
                                width={width}
                                symbol={selectedMarket?.symbol || 'ETHP'}
                            />
                            <FundingCountdown isDesktopView={isDesktopView} id={4} width={width} />
                        </SimpleGrid>
                    </Flex>
                </>
            )}
        </>
    );
}
