import { BigNumber } from '@0x/utils';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { getSelectedMarketTickerLow } from 'store/market/selectors';

import CommonStatsItem from './CommonStatsItem';
import { IStatsItem } from './Stats';

export default function TickerLow({
    id,
    isDesktopView,
    width,
}: {
    id: number;
    isDesktopView: boolean;
    width: number | undefined;
}) {
    const value = useSelector(getSelectedMarketTickerLow);
    const item: IStatsItem = {
        header: t('24HLow'),
        value: value && !value.isEqualTo(-1) ? value : new BigNumber(0),
        visibleWidthOnDesktop: 1100,
        thousandSeparator: true,
        decimalScale: 2,
    };
    return <CommonStatsItem id={id} isDesktopView={isDesktopView} item={item} width={width} />;
}
