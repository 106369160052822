import { Button, Flex, Text } from '@chakra-ui/react';
import CryptoIcon from 'components/CryptoIcon/CryptoIcon';
import { FiChevronDown } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedMarket } from 'store/market/selectors';
import { TOGGLE_MARKETS_DIALOG } from 'store/ui/slice';

import { useScreenSize } from '../../hooks/useScreenSize';

export default function MarketsButton() {
    const currentMarket = useSelector(getSelectedMarket);
    const dispatch = useDispatch();

    const isDesktopView = useScreenSize();

    function getCryptoIcon() {
        if (currentMarket?.symbol === 'BTCP') return 'BTC';
        else return 'ETH';
    }

    return (
        <Button
            id="markets-header-button"
            data-cy="markets-header-button"
            onClick={() => dispatch(TOGGLE_MARKETS_DIALOG())}
            rightIcon={<FiChevronDown />}
            colorScheme="rhino"
            color="white"
            mt="-0.25rem"
            mx={isDesktopView ? '1rem' : ''}
            ml={isDesktopView ? '' : '-0.5rem'}
            size={isDesktopView ? 'md' : 'sm'}
            backgroundColor={'card.100'}
            _hover={{
                bgColor: 'card.100',
                color: 'text.100',
            }}
            border="none"
            _focus={{
                outline: 'none',
            }}
        >
            <Flex alignItems="center">
                <CryptoIcon currency={getCryptoIcon()} size={4} />
                <Text ml="0.5rem" fontWeight="600">
                    {(currentMarket && currentMarket.symbol) || 'ETHP'}
                </Text>
            </Flex>
        </Button>
    );
}
