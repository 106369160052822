import { UIMarkPrice } from '@derivadex/types';
import { convertTickSize } from '@derivadex/utils';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useSelector, useStore } from 'react-redux';
import { getSelectedMarket } from 'store/market/selectors';

const selector = (state: any, symbol?: string) => {
    if (symbol === undefined) return undefined;
    if (state.market.markPrices[symbol] === undefined) return undefined;
    return state.market.markPrices[symbol] as UIMarkPrice;
};
export default function useOrderFormMarkPrice(
    userAlreadyTouched: boolean,
    setPrice: Dispatch<SetStateAction<string>>,
): void {
    const market = useSelector(getSelectedMarket);
    const store = useStore();
    useEffect(() => {
        if (!userAlreadyTouched) {
            const previousState = selector(store.getState(), market?.symbol);
            if (previousState !== undefined) {
                const priceTickSize = convertTickSize(market?.tickSize.toNumber() || 0);
                setPrice(previousState.price.dp(priceTickSize, 0).toString());
            }
        }
    }, [market, store, setPrice, userAlreadyTouched]);
}
