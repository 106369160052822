import { ActionType, UIStrategy } from '@derivadex/types';
import { getErrorMessage, getFrontendLogger } from '@derivadex/utils';
import { getStatsApiUrl } from 'store/config/selectors';
import { waitFor } from 'store/saga';
import { getSelectedStrategy } from 'store/strategy/selectors';
import { getEthAddress } from 'store/web3/selectors';
import { all, call, fork, putResolve, select, takeLatest } from 'typed-redux-saga/macro';

import {
    buildUrl,
    FetchDataState,
    generateChartPeriodParams,
    generateStatsMetricsPeriodParams,
    makeRequest,
    parseAdlResponse,
    parseBalanceResponse,
    parseDDXRewardsResponse,
    parseFeeResponse,
    parseOrderIntentResponse,
    parsePositionResponse,
    parseRealizedPnlResponse,
    parseStatsBalanceResponse,
    parseStatsResponse,
    parseStatsVolumeResponse,
    parseStrategyUpdateResponse,
} from '../requestUtils';
import {
    getAdlHistoryCache,
    getAdlHistoryCurrentPage,
    getAdlHistoryFilters,
    getAdlHistoryNextPageParams,
    getDDXRewardsHistoryCache,
    getDDXRewardsHistoryCurrentPage,
    getDDXRewardsHistoryNextPageParams,
    getDepositsWithdrawalsHistoryCache,
    getDepositsWithdrawalsHistoryCurrentPage,
    getDepositsWithdrawalsHistoryNextPageParams,
    getFeesHistoryCache,
    getFeesHistoryCurrentPage,
    getFeesHistoryFilters,
    getFeesHistoryNextPageParams,
    getFundingHistoryCache,
    getFundingHistoryCurrentPage,
    getFundingHistoryNextPageParams,
    getOrdersHistoryCache,
    getOrdersHistoryCurrentPage,
    getOrdersHistoryFilters,
    getOrdersHistoryNextPageParams,
    getPortfolioFilter,
} from './selectors';
import {
    FETCH_ADL_HISTORY_DATA,
    FETCH_DDX_REWARDS_HISTORY_DATA,
    FETCH_DEPOSITS_AND_WITHDRAWALS_HISTORY_DATA,
    FETCH_FEES_HISTORY_DATA,
    FETCH_FUNDING_HISTORY_DATA,
    FETCH_ORDERS_HISTORY_DATA,
    SET_ADL_HISTORY_CURRENT_PAGE,
    SET_ADL_HISTORY_DATA,
    SET_BALANCE_DATA,
    SET_DDX_REWARDS_HISTORY_CURRENT_PAGE,
    SET_DDX_REWARDS_HISTORY_DATA,
    SET_DEPOSITS_WITHDRAWALS_HISTORY_CURRENT_PAGE,
    SET_DEPOSITS_WITHDRAWALS_HISTORY_DATA,
    SET_FEES_HISTORY_CURRENT_PAGE,
    SET_FEES_HISTORY_DATA,
    SET_FUNDING_HISTORY_CURRENT_PAGE,
    SET_FUNDING_HISTORY_DATA,
    SET_LOOKBACK_FILTER,
    SET_ORDERS_HISTORY_CURRENT_PAGE,
    SET_ORDERS_HISTORY_DATA,
    SET_POSITIONS_HISTORY_DATA,
    SET_REALIZED_PNL_DATA,
    SET_STATS_BALANCE_DATA,
    SET_STATS_DIRTY_FLAG,
    SET_STATS_FUNDING_DATA,
    SET_STATS_REALIZED_DATA,
    SET_STATS_TRADE_MINING_DATA,
    SET_STATS_VOLUME_DATA,
    UPDATE_LOOKBACK_PERIOD_FILTER,
} from './slice';

function* handleDepositsWithdrawalsHistoryData(
    action: ReturnType<typeof FETCH_DEPOSITS_AND_WITHDRAWALS_HISTORY_DATA>,
): Generator {
    try {
        const state = action.payload;
        const cache = yield* select(getDepositsWithdrawalsHistoryCache);
        const currentPage = yield* select(getDepositsWithdrawalsHistoryCurrentPage);
        const nextPage = yield* select(getDepositsWithdrawalsHistoryNextPageParams);
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);
        const statsApiUrl = yield* select(getStatsApiUrl);
        if (traderAddress === undefined || strategy === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        let url = buildUrl(statsApiUrl, { account: traderAddress, strategy: strategy.strategy }, 'strategy_updates', {
            kind: ['0', '1', '2'],
            limit: 5,
        });
        if (state === FetchDataState.NextPage) {
            let params;
            if (nextPage != undefined) {
                params = {
                    kind: ['0', '1', '2'],
                    limit: 5,
                    order: 'desc',
                    epoch: nextPage.epoch!,
                    txOrdinal: nextPage.txOrdinal!,
                    ordinal: nextPage.ordinal!,
                };
            } else {
                params = { kind: ['0', '1', '2'], limit: 5, order: 'desc' };
            }
            url = buildUrl(
                statsApiUrl,
                { account: traderAddress, strategy: strategy.strategy },
                'strategy_updates',
                params,
            );
            const response = yield* call(makeRequest, url);
            const parsedResponse = yield* call(parseStrategyUpdateResponse, response);
            if (nextPage) {
                const data = [...cache, parsedResponse];
                yield* putResolve(SET_DEPOSITS_WITHDRAWALS_HISTORY_DATA(data));
                yield* putResolve(SET_DEPOSITS_WITHDRAWALS_HISTORY_CURRENT_PAGE(currentPage + 1));
            } else {
                yield* putResolve(SET_DEPOSITS_WITHDRAWALS_HISTORY_DATA([parsedResponse]));
                yield* putResolve(SET_DEPOSITS_WITHDRAWALS_HISTORY_CURRENT_PAGE(currentPage + 1));
            }
        }
        if (state === FetchDataState.PrevPage) {
            yield* putResolve(SET_DEPOSITS_WITHDRAWALS_HISTORY_CURRENT_PAGE(currentPage - 1));
        }
    } catch (error: any) {
        getFrontendLogger().logError(
            'caught exception in fetching deposits and withdrawals data',
            getErrorMessage(error),
        );
    }
}

export function* watchDepositsWithdrawalsHistoryData() {
    yield* takeLatest(ActionType.FETCH_DEPOSITS_AND_WITHDRAWALS_DATA, handleDepositsWithdrawalsHistoryData);
}

function* handleDDXRewardsHistoryData(action: ReturnType<typeof FETCH_DDX_REWARDS_HISTORY_DATA>): Generator {
    try {
        const state = action.payload;
        const cache = yield* select(getDDXRewardsHistoryCache);
        const currentPage = yield* select(getDDXRewardsHistoryCurrentPage);
        const nextPage = yield* select(getDDXRewardsHistoryNextPageParams);
        const traderAddress = yield* select(getEthAddress);
        const statsApiUrl = yield* select(getStatsApiUrl);
        if (traderAddress === undefined) {
            getFrontendLogger().logError('Trader address cannot be undefined');
            return;
        }
        let url = buildUrl(statsApiUrl, { account: traderAddress }, 'trader_updates', { kind: 3, limit: 5 });
        if (state === FetchDataState.NextPage) {
            let params;
            if (nextPage != undefined) {
                params = {
                    kind: 3,
                    limit: 5,
                    order: 'desc',
                    epoch: nextPage.epoch!,
                    txOrdinal: nextPage.txOrdinal!,
                    ordinal: nextPage.ordinal!,
                };
            } else {
                params = { kind: 3, limit: 5, order: 'desc' };
            }
            url = buildUrl(statsApiUrl, { account: traderAddress }, 'trader_updates', params);
            const response = yield* call(makeRequest, url);
            const parsedResponse = yield* call(parseDDXRewardsResponse, response);
            if (nextPage) {
                const data = [...cache, parsedResponse];
                yield* putResolve(SET_DDX_REWARDS_HISTORY_DATA(data));
                yield* putResolve(SET_DDX_REWARDS_HISTORY_CURRENT_PAGE(currentPage + 1));
            } else {
                yield* putResolve(SET_DDX_REWARDS_HISTORY_DATA([parsedResponse]));
                yield* putResolve(SET_DDX_REWARDS_HISTORY_CURRENT_PAGE(currentPage + 1));
            }
        }
        if (state === FetchDataState.PrevPage) {
            yield* putResolve(SET_DDX_REWARDS_HISTORY_CURRENT_PAGE(currentPage - 1));
        }
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching ddx rewards data', getErrorMessage(error));
    }
}

export function* watchDDXRewardsHistoryData() {
    yield* takeLatest(ActionType.FETCH_DDX_REWARDS_DATA, handleDDXRewardsHistoryData);
}

function* handleFundingHistoryData(action: ReturnType<typeof FETCH_FUNDING_HISTORY_DATA>): Generator {
    try {
        const state = action.payload;
        const cache = yield* select(getFundingHistoryCache);
        const currentPage = yield* select(getFundingHistoryCurrentPage);
        const nextPage = yield* select(getFundingHistoryNextPageParams);
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);
        const statsApiUrl = yield* select(getStatsApiUrl);
        if (traderAddress === undefined || strategy === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        let url = buildUrl(statsApiUrl, { account: traderAddress, strategy: strategy.strategy }, 'strategy_updates', {
            kind: 3,
            limit: 5,
            order: 'desc',
        });
        if (state === FetchDataState.NextPage) {
            let params;
            if (nextPage != undefined) {
                params = {
                    kind: 3,
                    limit: 5,
                    order: 'desc',
                    epoch: nextPage.epoch!,
                    txOrdinal: nextPage.txOrdinal!,
                    ordinal: nextPage.ordinal!,
                };
            } else {
                params = { kind: 3, limit: 5, order: 'desc' };
            }
            url = buildUrl(
                statsApiUrl,
                { account: traderAddress, strategy: strategy.strategy },
                'strategy_updates',
                params,
            );
            const response = yield* call(makeRequest, url);
            const parsedResponse = yield* call(parseStrategyUpdateResponse, response);
            if (nextPage) {
                const data = [...cache, parsedResponse];
                yield* putResolve(SET_FUNDING_HISTORY_DATA(data));
                yield* putResolve(SET_FUNDING_HISTORY_CURRENT_PAGE(currentPage + 1));
            } else {
                yield* putResolve(SET_FUNDING_HISTORY_DATA([parsedResponse]));
                yield* putResolve(SET_FUNDING_HISTORY_CURRENT_PAGE(currentPage + 1));
            }
        }
        if (state === FetchDataState.PrevPage) {
            yield* putResolve(SET_FUNDING_HISTORY_CURRENT_PAGE(currentPage - 1));
        }
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching funding data', getErrorMessage(error));
    }
}

export function* watchFundingHistoryData() {
    yield* takeLatest(ActionType.FETCH_FUNDING_HISTORY_DATA, handleFundingHistoryData);
}

function* handlePositionHistoryDirtyFlag(): Generator {
    try {
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);
        const statsApiUrl = yield* select(getStatsApiUrl);
        if (traderAddress === undefined || strategy === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        const url = buildUrl(statsApiUrl, { account: traderAddress, strategy: strategy.strategy }, 'positions', {});
        const response = yield* call(makeRequest, url);
        const parsedResponse = yield* call(parsePositionResponse, response);
        yield* putResolve(SET_POSITIONS_HISTORY_DATA(parsedResponse));
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching positions data', getErrorMessage(error));
    }
}

export function* watchPositionHistoryDirtyFlag() {
    yield* takeLatest(ActionType.FETCH_POSITIONS_HISTORY_DATA, handlePositionHistoryDirtyFlag);
}

function* handleOrdersHistoryData(action: ReturnType<typeof FETCH_ORDERS_HISTORY_DATA>): Generator {
    try {
        const state = action.payload;
        const cache = yield* select(getOrdersHistoryCache);
        const currentPage = yield* select(getOrdersHistoryCurrentPage);
        const nextPage = yield* select(getOrdersHistoryNextPageParams);
        const filters = yield* select(getOrdersHistoryFilters);
        const filterByStatus = filters.filterByStatus.length > 0 ? filters.filterByStatus : undefined;
        const symbol = filters.symbol === 'all' ? undefined : filters.symbol;
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);
        const statsApiUrl = yield* select(getStatsApiUrl);
        if (traderAddress === undefined || strategy === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        let url = buildUrl(statsApiUrl, { account: traderAddress, strategy: strategy.strategy }, 'order_intents', {
            filterByStatus,
            symbol,
        });
        if (state === FetchDataState.NextPage) {
            let params;
            if (nextPage != undefined) {
                params = {
                    filterByStatus,
                    symbol,
                    limit: 5,
                    order: 'desc',
                    epoch: nextPage.epoch!,
                    txOrdinal: nextPage.txOrdinal!,
                    ordinal: nextPage.ordinal!,
                };
            } else {
                params = { filterByStatus, symbol, limit: 5, order: 'desc' };
            }
            url = buildUrl(
                statsApiUrl,
                { account: traderAddress, strategy: strategy.strategy },
                'order_intents',
                params,
            );
            const response = yield* call(makeRequest, url);
            const parsedResponse = yield* call(parseOrderIntentResponse, response);
            if (nextPage) {
                const data = [...cache, parsedResponse];
                yield* putResolve(SET_ORDERS_HISTORY_DATA(data));
                yield* putResolve(SET_ORDERS_HISTORY_CURRENT_PAGE(currentPage + 1));
            } else {
                yield* putResolve(SET_ORDERS_HISTORY_DATA([parsedResponse]));
                yield* putResolve(SET_ORDERS_HISTORY_CURRENT_PAGE(currentPage + 1));
            }
        }
        if (state === FetchDataState.PrevPage) {
            yield* putResolve(SET_ORDERS_HISTORY_CURRENT_PAGE(currentPage - 1));
        }
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching open orders data', getErrorMessage(error));
    }
}

export function* watchOrdersHistoryData() {
    yield* takeLatest(ActionType.FETCH_ORDERS_HISTORY_DATA, handleOrdersHistoryData);
}

function* handleAdlHistoryData(action: ReturnType<typeof FETCH_ADL_HISTORY_DATA>): Generator {
    try {
        const state = action.payload;
        const cache = yield* select(getAdlHistoryCache);
        const currentPage = yield* select(getAdlHistoryCurrentPage);
        const nextPage = yield* select(getAdlHistoryNextPageParams);
        const filters = yield* select(getAdlHistoryFilters);
        const symbol = filters.symbol === 'all' ? undefined : filters.symbol;
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);
        const statsApiUrl = yield* select(getStatsApiUrl);
        if (traderAddress === undefined || strategy === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        let url = buildUrl(statsApiUrl, { account: traderAddress, strategy: strategy.strategy }, 'adl', {
            symbol,
        });
        if (state === FetchDataState.NextPage) {
            let params;
            if (nextPage != undefined) {
                params = {
                    limit: 5,
                    symbol,
                    order: 'desc',
                    epoch: nextPage.epoch!,
                    txOrdinal: nextPage.txOrdinal!,
                    ordinal: nextPage.ordinal!,
                };
            } else {
                params = { limit: 5, symbol, order: 'desc' };
            }
            url = buildUrl(statsApiUrl, { account: traderAddress, strategy: strategy.strategy }, 'adl', params);
            const response = yield* call(makeRequest, url);
            const parsedResponse = yield* call(parseAdlResponse, response);
            if (nextPage) {
                const data = [...cache, parsedResponse];
                yield* putResolve(SET_ADL_HISTORY_DATA(data));
                yield* putResolve(SET_ADL_HISTORY_CURRENT_PAGE(currentPage + 1));
            } else {
                yield* putResolve(SET_ADL_HISTORY_DATA([parsedResponse]));
                yield* putResolve(SET_ADL_HISTORY_CURRENT_PAGE(currentPage + 1));
            }
        }
        if (state === FetchDataState.PrevPage) {
            yield* putResolve(SET_ADL_HISTORY_CURRENT_PAGE(currentPage - 1));
        }
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching adl data', getErrorMessage(error));
    }
}

export function* watchAdlHistoryData() {
    yield* takeLatest(ActionType.FETCH_ADL_HISTORY_DATA, handleAdlHistoryData);
}

function* handleFetchStatsRealized(): Generator {
    try {
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);
        const filter = yield* select(getPortfolioFilter);
        const statsApiUrl = yield* select(getStatsApiUrl);
        if (traderAddress === undefined || strategy === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        const { aggregationPeriod, lookbackCount } = generateStatsMetricsPeriodParams(filter);
        const url = buildUrl(
            `${statsApiUrl}/aggregations`,
            { account: traderAddress, strategy: strategy.strategy },
            'realized_pnl',
            { aggregationPeriod, lookbackCount },
        );
        const response = yield* call(makeRequest, url);
        const parsedResponse = yield* call(parseStatsResponse, response, traderAddress!);
        yield* putResolve(SET_STATS_REALIZED_DATA(parsedResponse));
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching adl data', getErrorMessage(error));
    }
}

function* handleFetchStatsBalance(): Generator {
    try {
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);
        const filter = yield* select(getPortfolioFilter);
        const statsApiUrl = yield* select(getStatsApiUrl);
        if (traderAddress === undefined || strategy === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        const { aggregationPeriod, lookbackCount } = generateStatsMetricsPeriodParams(filter);
        const url = buildUrl(
            `${statsApiUrl}/aggregations`,
            { account: traderAddress, strategy: strategy.strategy },
            'balance',
            { aggregationPeriod, lookbackCount },
        );
        const response = yield* call(makeRequest, url);
        const parsedResponse = yield* call(parseStatsBalanceResponse, response, strategy);
        yield* putResolve(SET_STATS_BALANCE_DATA(parsedResponse));
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching adl data', getErrorMessage(error));
    }
}

function* handleFetchStatsFunding(): Generator {
    try {
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);
        const filter = yield* select(getPortfolioFilter);
        const statsApiUrl = yield* select(getStatsApiUrl);
        if (traderAddress === undefined || strategy === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        const { aggregationPeriod, lookbackCount } = generateStatsMetricsPeriodParams(filter);
        const url = buildUrl(
            `${statsApiUrl}/aggregations`,
            { account: traderAddress, strategy: strategy.strategy },
            'funding_rate_payments',
            { aggregationPeriod, lookbackCount },
        );
        const response = yield* call(makeRequest, url);
        const parsedResponse = yield* call(parseStatsResponse, response, traderAddress!);
        yield* putResolve(SET_STATS_FUNDING_DATA(parsedResponse));
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching adl data', getErrorMessage(error));
    }
}

function* handleFetchStatsTradeMining(): Generator {
    try {
        const traderAddress = yield* select(getEthAddress);
        const filter = yield* select(getPortfolioFilter);
        const statsApiUrl = yield* select(getStatsApiUrl);
        if (traderAddress === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        const { aggregationPeriod, lookbackCount } = generateStatsMetricsPeriodParams(filter);
        const url = buildUrl(`${statsApiUrl}/aggregations`, { account: traderAddress }, 'trade_mining_rewards', {
            aggregationPeriod,
            lookbackCount,
        });
        const response = yield* call(makeRequest, url);
        const parsedResponse = yield* call(parseStatsResponse, response, traderAddress!);
        yield* putResolve(SET_STATS_TRADE_MINING_DATA(parsedResponse));
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching adl data', getErrorMessage(error));
    }
}

function* handleFetchStatsVolume(): Generator {
    try {
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);
        const filter = yield* select(getPortfolioFilter);
        const statsApiUrl = yield* select(getStatsApiUrl);
        if (traderAddress === undefined || strategy === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        const { aggregationPeriod, lookbackCount } = generateStatsMetricsPeriodParams(filter);
        const url = buildUrl(
            `${statsApiUrl}/aggregations`,
            { account: traderAddress, strategy: strategy.strategy },
            'volume',
            { aggregationPeriod, lookbackCount },
        );
        const response = yield* call(makeRequest, url);
        const parsedResponse = yield* call(parseStatsVolumeResponse, response, traderAddress!);
        yield* putResolve(SET_STATS_VOLUME_DATA(parsedResponse));
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching adl data', getErrorMessage(error));
    }
}

function* handleFetchStats(): Generator {
    yield* all([
        fork(handleFetchStatsVolume),
        fork(handleFetchStatsTradeMining),
        fork(handleFetchStatsFunding),
        fork(handleFetchStatsBalance),
        fork(handleFetchStatsRealized),
    ]);
    yield* putResolve(SET_STATS_DIRTY_FLAG(false));
}

export function* watchStatsDirtyFlag() {
    yield* takeLatest(ActionType.FETCH_STATS_DATA, handleFetchStats);
}

function* handleBalanceDirtyFlag(): Generator {
    try {
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);
        const filter = yield* select(getPortfolioFilter);
        const statsApiUrl = yield* select(getStatsApiUrl);
        if (traderAddress === undefined || strategy === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        const { aggregationPeriod, lookbackCount } = generateChartPeriodParams(filter);
        const url = buildUrl(
            `${statsApiUrl}/aggregations`,
            { account: traderAddress, strategy: strategy.strategy },
            'balance',
            { aggregationPeriod, lookbackCount },
        );
        const response = yield* call(makeRequest, url);
        const parsedResponse = yield* call(parseBalanceResponse, response!);
        yield* putResolve(SET_BALANCE_DATA(parsedResponse));
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching adl data', getErrorMessage(error));
    }
}

export function* watchBalanceDirtyFlag() {
    yield* takeLatest(ActionType.FETCH_BALANCE_DATA, handleBalanceDirtyFlag);
}

function* handleFeesHistoryData(action: ReturnType<typeof FETCH_FEES_HISTORY_DATA>): Generator {
    try {
        const state = action.payload;
        const cache = yield* select(getFeesHistoryCache);
        const currentPage = yield* select(getFeesHistoryCurrentPage);
        const nextPage = yield* select(getFeesHistoryNextPageParams);
        const filters = yield* select(getFeesHistoryFilters);
        const symbol = filters.symbol === 'all' ? undefined : filters.symbol;
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);
        const statsApiUrl = yield* select(getStatsApiUrl);
        if (traderAddress === undefined || strategy === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        let url = buildUrl(statsApiUrl, { account: traderAddress, strategy: strategy.strategy }, 'fees', {
            limit: 5,
            symbol,
        });
        if (state === FetchDataState.NextPage) {
            let params;
            getFrontendLogger().log('Fees Next Page', nextPage);
            if (nextPage != undefined) {
                params = {
                    limit: 5,
                    symbol,
                    order: 'desc',
                    epoch: nextPage.epoch!,
                    txOrdinal: nextPage.txOrdinal!,
                    ordinal: nextPage.ordinal!,
                };
            } else {
                params = { limit: 5, symbol, order: 'desc' };
            }
            url = buildUrl(statsApiUrl, { account: traderAddress, strategy: strategy.strategy }, 'fees', params);
            const response = yield* call(makeRequest, url);
            const parsedResponse = yield* call(parseFeeResponse, response);
            if (nextPage) {
                getFrontendLogger().log('Fees Going to this next page path');
                const data = [...cache, parsedResponse];
                yield* putResolve(SET_FEES_HISTORY_DATA(data));
                yield* putResolve(SET_FEES_HISTORY_CURRENT_PAGE(currentPage + 1));
            } else {
                getFrontendLogger().log('Fees Going to this first page path');
                yield* putResolve(SET_FEES_HISTORY_DATA([parsedResponse]));
                yield* putResolve(SET_FEES_HISTORY_CURRENT_PAGE(currentPage + 1));
            }
        }
        if (state === FetchDataState.PrevPage) {
            yield* putResolve(SET_FEES_HISTORY_CURRENT_PAGE(currentPage - 1));
        }
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching adl data', getErrorMessage(error));
    }
}

export function* watchFeesHistoryData() {
    yield* takeLatest(ActionType.FETCH_FEE_HISTORY_DATA, handleFeesHistoryData);
}

function* handleRealizedPnlDirtyFlag(): Generator {
    try {
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);
        const filter = yield* select(getPortfolioFilter);
        const statsApiUrl = yield* select(getStatsApiUrl);
        if (traderAddress === undefined || strategy === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        const { aggregationPeriod, lookbackCount } = generateChartPeriodParams(filter);
        const url = buildUrl(
            `${statsApiUrl}/aggregations`,
            { account: traderAddress, strategy: strategy.strategy },
            'realized_pnl',
            { aggregationPeriod, lookbackCount },
        );
        const response = yield* call(makeRequest, url);
        const parsedResponse = yield* call(parseRealizedPnlResponse, response!);
        yield* putResolve(SET_REALIZED_PNL_DATA(parsedResponse));
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching adl data', getErrorMessage(error));
    }
}

export function* watchRealizedPnlDirtyFlag() {
    yield* takeLatest(ActionType.FETCH_REALIZED_PNL_DATA, handleRealizedPnlDirtyFlag);
}

function* handleLookbackPeriodFilter(action: ReturnType<typeof UPDATE_LOOKBACK_PERIOD_FILTER>): Generator {
    try {
        const filter = action.payload;
        yield* call(waitFor<UIStrategy>, getSelectedStrategy);
        yield* putResolve(SET_LOOKBACK_FILTER(filter));
        yield* all([fork(handleRealizedPnlDirtyFlag), fork(handleFetchStats)]);
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in handling lookback period filter', getErrorMessage(error));
    }
}

export function* watchLookbackPeriodFilter() {
    yield* takeLatest(ActionType.UPDATE_LOOKBACK_PERIOD_FILTER, handleLookbackPeriodFilter);
}

/**
 * The portfolio saga layer is responsable for handling side effects for the portfolio dashboard
 */
export const portfolioSaga = function* root() {
    yield* all([
        fork(watchDepositsWithdrawalsHistoryData),
        fork(watchDDXRewardsHistoryData),
        fork(watchFundingHistoryData),
        fork(watchPositionHistoryDirtyFlag),
        fork(watchOrdersHistoryData),
        fork(watchAdlHistoryData),
        fork(watchBalanceDirtyFlag),
        fork(watchFeesHistoryData),
        fork(watchRealizedPnlDirtyFlag),
        fork(watchStatsDirtyFlag),
        fork(watchLookbackPeriodFilter),
    ]);
};
