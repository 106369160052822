import { Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from '@chakra-ui/react';
import { OrderSide, OrderType } from '@derivadex/types';
import { useScreenSize } from 'hooks/useScreenSize';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import StrategyDetails from '../StrategyDetails';
import ProfileDetails from './ProfileDetails';
import OrderForm from './Tabs/OrderForm';

export default function BuySell() {
    const { t } = useTranslation();
    const [tabIndex, setTabIndex] = useState(0);
    const isDesktopView = useScreenSize();

    function OrderTab({ children, className, tooltip, ...props }: any) {
        return (
            <Tab
                className={className}
                variant="unstyled"
                borderRadius="base"
                fontSize={isDesktopView ? '0.75rem' : '0.875rem'}
                fontWeight="600"
                py="0.25rem"
                _selected={{
                    opactiy: 1,
                    color: 'white',
                    bg: 'rhino.300',
                }}
                _hover={{
                    color: 'white',
                    bg: 'rhino.100',
                }}
                _active={{
                    color: 'white',
                    bg: 'rhino.400',
                }}
                _disabled={{
                    cursor: 'not-allowed',
                    opacity: 0.4,
                }}
                {...props}
            >
                {children}
            </Tab>
        );
    }

    function OrderTypePanel({ type }: { type: OrderSide }) {
        return (
            <Tabs
                isLazy
                isFitted
                size={isDesktopView ? 'md' : 'xs'}
                variant="unstyled"
                index={tabIndex}
                onChange={(index) => setTabIndex(index)}
            >
                <TabList bgColor="background.100" p="0.25rem" my="0.5rem" borderRadius="base" border="none">
                    <OrderTab
                        tooltip={t('marketOrderTooltip')}
                        selected={tabIndex === 0}
                        size={isDesktopView ? 'md' : 'xs'}
                    >
                        {t('market')}
                    </OrderTab>
                    <OrderTab
                        className="limit-order"
                        data-cy="limit-order"
                        tooltip={t('limitOrderTooltip')}
                        selected={tabIndex === 1}
                        mx="0.125rem"
                        size={isDesktopView ? 'md' : 'xs'}
                    >
                        {t('limit')}
                    </OrderTab>
                    <OrderTab
                        tooltip={t('stopOrder')}
                        selected={tabIndex === 2}
                        size={isDesktopView ? 'md' : 'xs'}
                        isDisabled
                    >
                        {t('stop')}
                    </OrderTab>
                </TabList>
                <TabPanels>
                    <TabPanel p="0">
                        <OrderForm orderSide={type} orderType={OrderType.Market} />
                    </TabPanel>
                    <TabPanel p="0">
                        <OrderForm orderSide={type} orderType={OrderType.Limit} />
                    </TabPanel>
                    <TabPanel p="0">{null}</TabPanel>
                </TabPanels>
            </Tabs>
        );
    }

    return (
        <Tabs isLazy isFitted size={isDesktopView ? 'md' : 'sm'}>
            <TabList color="text.100" mr={isDesktopView ? '' : '10vw'}>
                <Tab
                    id="buy-tab"
                    data-cy="buy-tab"
                    fontWeight="600"
                    fontSize={isDesktopView ? 'xs' : 'sm'}
                    _selected={{
                        borderColor: 'brand.green.300',
                        borderWidth: '0 0 2px',
                    }}
                    _hover={{
                        borderColor: 'brand.green.100',
                        borderWidth: '0 0 2px',
                        color: 'white',
                    }}
                    _focus={{}}
                >
                    {t('buy')}
                </Tab>
                <Tab
                    className="sell-tab"
                    data-cy="sell-tab"
                    fontWeight="600"
                    fontSize={isDesktopView ? 'xs' : 'sm'}
                    _selected={{
                        borderColor: 'brand.red.300',
                        borderWidth: '0 0 2px',
                    }}
                    _hover={{
                        borderColor: 'brand.red.100',
                        borderWidth: '0 0 2px',
                        color: 'white',
                    }}
                    _focus={{}}
                >
                    {t('sell')}
                </Tab>
            </TabList>
            <TabPanels>
                <TabPanel p="0.25rem">
                    <OrderTypePanel type={OrderSide.Bid} />
                </TabPanel>
                <TabPanel p="0.25rem">
                    <OrderTypePanel type={OrderSide.Ask} />
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
}
