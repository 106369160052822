import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getDisablePlaySoundForFills } from 'store/profile/selectors';
import { getPositions, getPositionsDirtyFlag } from 'store/strategy/selectors';
import useSound from 'use-sound';

export default function SoundEffects() {
    const positionsDirtyFlag = useSelector(getPositionsDirtyFlag);
    const positions = useSelector(getPositions);
    const disablePlaySoundForFills = useSelector(getDisablePlaySoundForFills);

    const [playSound] = useSound('/fill.mp3', {
        volume: 0.25,
    });

    useEffect(() => {
        // skip sound effects for initial load
        if (positionsDirtyFlag) {
            return;
        }
        if (positions && Object.keys(positions).length > 0 && !disablePlaySoundForFills) {
            playSound();
        }
    }, [positions]);

    return null;
}
