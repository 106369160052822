import { AppState } from 'store/slices';

export function getDepositsWithdrawalsHistoryData(state: AppState) {
    return state.portfolio.depositsAndWithdrawals.cache[
        Math.min(state.portfolio.depositsAndWithdrawals.currentPage - 1, 0)
    ];
}

export function getDepositsWithdrawalsHistoryCache(state: AppState) {
    return state.portfolio.depositsAndWithdrawals.cache;
}

export function getDepositsWithdrawalsHistoryNextPageParams(state: AppState) {
    const data = state.portfolio.depositsAndWithdrawals.cache[state.portfolio.depositsAndWithdrawals.currentPage - 1];
    if (data && data.nextEpoch && data.nextTxOrdinal && data.nextOrdinal) {
        return { epoch: data.nextEpoch, txOrdinal: data.nextTxOrdinal, ordinal: data.nextOrdinal };
    }
    return undefined;
}

export function getDepositsWithdrawalsHistoryPrevPageParams(state: AppState) {
    const data = state.portfolio.depositsAndWithdrawals.cache[state.portfolio.depositsAndWithdrawals.currentPage - 2];
    if (data) {
        return { epoch: data.nextEpoch, txOrdinal: data.nextTxOrdinal, ordinal: data.nextOrdinal };
    }
    return undefined;
}

export function getDepositsWithdrawalsHistoryCurrentPage(state: AppState) {
    return state.portfolio.depositsAndWithdrawals.currentPage;
}

export function getDDXRewardsHistoryData(state: AppState) {
    return state.portfolio.ddxRewards.cache[Math.min(state.portfolio.ddxRewards.currentPage - 1)];
}

export function getDDXRewardsHistoryCache(state: AppState) {
    return state.portfolio.ddxRewards.cache;
}

export function getDDXRewardsHistoryNextPageParams(state: AppState) {
    const data = state.portfolio.ddxRewards.cache[state.portfolio.ddxRewards.currentPage - 1];
    if (data && data.nextEpoch && data.nextTxOrdinal && data.nextOrdinal) {
        return { epoch: data.nextEpoch, txOrdinal: data.nextTxOrdinal, ordinal: data.nextOrdinal };
    }
    return undefined;
}

export function getDDXRewardsHistoryPrevPageParams(state: AppState) {
    const data = state.portfolio.ddxRewards.cache[state.portfolio.ddxRewards.currentPage - 2];
    if (data) {
        return { epoch: data.nextEpoch, txOrdinal: data.nextTxOrdinal, ordinal: data.nextOrdinal };
    }
    return undefined;
}

export function getDDXRewardsHistoryCurrentPage(state: AppState) {
    return state.portfolio.ddxRewards.currentPage;
}

export function getPositionsHistoryData(state: AppState) {
    return state.portfolio.positionsHistory.data;
}

export function getPositionsHistoryDirtyFlag(state: AppState) {
    return state.portfolio.positionsHistory.dirtyFlag;
}

export function getFundingHistoryData(state: AppState) {
    return state.portfolio.fundingHistory.cache[Math.min(state.portfolio.fundingHistory.currentPage - 1, 0)];
}

export function getFundingHistoryCache(state: AppState) {
    return state.portfolio.fundingHistory.cache;
}

export function getFundingHistoryNextPageParams(state: AppState) {
    const data = state.portfolio.fundingHistory.cache[state.portfolio.fundingHistory.currentPage - 1];
    if (data && data.nextEpoch && data.nextTxOrdinal && data.nextOrdinal) {
        return { epoch: data.nextEpoch, txOrdinal: data.nextTxOrdinal, ordinal: data.nextOrdinal };
    }
    return undefined;
}

export function getFundingHistoryPrevPageParams(state: AppState) {
    const data = state.portfolio.fundingHistory.cache[state.portfolio.fundingHistory.currentPage - 2];
    if (data) {
        return { epoch: data.nextEpoch, txOrdinal: data.nextTxOrdinal, ordinal: data.nextOrdinal };
    }
    return undefined;
}

export function getFundingHistoryCurrentPage(state: AppState) {
    return state.portfolio.fundingHistory.currentPage;
}

export function getStatsDirtyFlag(state: AppState) {
    return state.portfolio.stats.dirtyFlag;
}

export function getStatsRealizedPnlData(state: AppState) {
    return state.portfolio.stats.realizedPnl;
}

export function getStatsBalanceData(state: AppState) {
    return state.portfolio.stats.balance;
}

export function getStatsFundingData(state: AppState) {
    return state.portfolio.stats.funding;
}

export function getStatsTradeMiningData(state: AppState) {
    return state.portfolio.stats.tradeMining;
}

export function getStatsMakerVolumeData(state: AppState) {
    return state.portfolio.stats.makerVolume;
}

export function getStatsTakerVolumeData(state: AppState) {
    return state.portfolio.stats.takerVolume;
}

export function getBalanceHistoryData(state: AppState) {
    return state.portfolio.balanceHistory.data;
}

export function getBalanceHistoryDirtyFlag(state: AppState) {
    return state.portfolio.balanceHistory.dirtyFlag;
}

export function getRealizedPnlHistoryData(state: AppState) {
    return state.portfolio.realizedPnlHistory.data;
}

export function getRealizedPnlHistoryDirtyFlag(state: AppState) {
    return state.portfolio.realizedPnlHistory.dirtyFlag;
}

export function getPortfolioFilter(state: AppState) {
    return state.portfolio.lookbackFilter;
}

export function getFeesHistoryData(state: AppState) {
    return state.portfolio.feesHistory.cache[state.portfolio.feesHistory.currentPage - 1];
}

export function getFeesHistoryCache(state: AppState) {
    return state.portfolio.feesHistory.cache;
}

export function getFeesHistoryNextPageParams(state: AppState) {
    const data = state.portfolio.feesHistory.cache[state.portfolio.feesHistory.currentPage - 1];
    if (data && data.nextEpoch && data.nextTxOrdinal && data.nextOrdinal) {
        return { epoch: data.nextEpoch, txOrdinal: data.nextTxOrdinal, ordinal: data.nextOrdinal };
    }
    return undefined;
}

export function getFeesHistoryPrevPageParams(state: AppState) {
    const data = state.portfolio.feesHistory.cache[state.portfolio.feesHistory.currentPage - 2];
    if (data) {
        return { epoch: data.nextEpoch, txOrdinal: data.nextTxOrdinal, ordinal: data.nextOrdinal };
    }
    return undefined;
}

export function getFeesHistoryCurrentPage(state: AppState) {
    return state.portfolio.feesHistory.currentPage;
}

export function getAdlHistoryData(state: AppState) {
    return state.portfolio.adlHistory.cache[state.portfolio.adlHistory.currentPage - 1];
}

export function getAdlHistoryCache(state: AppState) {
    return state.portfolio.adlHistory.cache;
}

export function getAdlHistoryNextPageParams(state: AppState) {
    const data = state.portfolio.adlHistory.cache[state.portfolio.adlHistory.currentPage - 1];
    if (data && data.nextEpoch && data.nextTxOrdinal && data.nextOrdinal) {
        return { epoch: data.nextEpoch, txOrdinal: data.nextTxOrdinal, ordinal: data.nextOrdinal };
    }
    return undefined;
}

export function getAdlHistoryPrevPageParams(state: AppState) {
    const data = state.portfolio.adlHistory.cache[state.portfolio.adlHistory.currentPage - 2];
    if (data) {
        return { epoch: data.nextEpoch, txOrdinal: data.nextTxOrdinal, ordinal: data.nextOrdinal };
    }
    return undefined;
}

export function getAdlHistoryCurrentPage(state: AppState) {
    return state.portfolio.adlHistory.currentPage;
}

export function getOrdersHistoryData(state: AppState) {
    return state.portfolio.ordersHistory.cache[state.portfolio.ordersHistory.currentPage - 1];
}

export function getOrdersHistoryCache(state: AppState) {
    return state.portfolio.ordersHistory.cache;
}

export function getOrdersHistoryNextPageParams(state: AppState) {
    const data = state.portfolio.ordersHistory.cache[state.portfolio.ordersHistory.currentPage - 1];
    if (data && data.nextEpoch && data.nextTxOrdinal && data.nextOrdinal) {
        return { epoch: data.nextEpoch, txOrdinal: data.nextTxOrdinal, ordinal: data.nextOrdinal };
    }
    return undefined;
}

export function getOrdersHistoryPrevPageParams(state: AppState) {
    const data = state.portfolio.ordersHistory.cache[state.portfolio.ordersHistory.currentPage - 2];
    if (data) {
        return { epoch: data.nextEpoch, txOrdinal: data.nextTxOrdinal, ordinal: data.nextOrdinal };
    }
    return undefined;
}

export function getOrdersHistoryCurrentPage(state: AppState) {
    return state.portfolio.ordersHistory.currentPage;
}

export function getOrdersHistoryFilters(state: AppState) {
    return state.portfolio.ordersHistory.filters;
}

export function getAdlHistoryFilters(state: AppState) {
    return state.portfolio.adlHistory.filters;
}

export function getFeesHistoryFilters(state: AppState) {
    return state.portfolio.feesHistory.filters;
}
