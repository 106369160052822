import { GridItem } from '@chakra-ui/react';
import { ReactNode } from 'react';

export default function CardGridItem({
    gridArea,
    children,
    ...props
}: {
    gridArea: string;
    children: ReactNode;
    [x: string]: any;
}) {
    return (
        <GridItem
            gridArea={gridArea}
            borderRadius="base"
            bgColor="card.100"
            p="0rem"
            sx={{
                '::-webkit-scrollbar': {
                    display: 'none',
                },
                scrollbarWidth: 'none',
            }}
            {...props}
        >
            {children}
        </GridItem>
    );
}
