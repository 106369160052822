import { BigNumber } from '@0x/utils';
import { Box, Flex, FormControl, FormLabel, HStack, Text, useToken } from '@chakra-ui/react';
import { OrderSide } from '@derivadex/types';
import styled from '@emotion/styled';
import TooltipWrapper from 'components/Tooltip/TooltipWrapper';
import { useOrderAmountUpdate } from 'hooks/useOrderAmountUpdate';
import { useScreenSize } from 'hooks/useScreenSize';
import { t } from 'i18next';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ReactSlider from 'react-slider';
import { getSelectedMarket } from 'store/market/selectors';
import { getSelectedStrategyAvailableCollateral, getSelectedStrategyLeverage } from 'store/strategy/selectors';
import { FRONTEND_MAX_LEVERAGE_PERCENTAGE } from 'utils/constants';

enum Intent {
    NONE,
    DANGER,
}

interface IProps {
    orderSide: OrderSide;
    maxLeverage: number;
    isConnected: boolean;
    leverageState: Intent | null;
    setLeverageState: Dispatch<SetStateAction<Intent | null>>;
    leverage: number;
    setLeverage: Dispatch<SetStateAction<number>>;
    setAmount: Dispatch<SetStateAction<string>>;
    price: string;
}

const StyledSlider = styled(ReactSlider)`
    -webkit-tap-highlight-color: transparent;
    border: 0 solid #e2e8f0;
    outline: 0;
    padding-bottom: 7px;
    padding-top: 7px;
    overflow-wrap: break-word;
    position: relative;
    touch-action: none;
    user-select: none;
    display: inline-block;
    width: 100%;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
`;

const StyledThumb = styled(Box)`
    -webkit-box-align: center;
    -webkit-box-pack: center;
    align-items: center;
    background: white;
    border: 1px solid transparent;
    border-radius: 9999px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    display: flex;
    height: 0.875rem;
    justify-content: center;
    left: calc(33% - 7px);
    outline: 2px solid transparent;
    outline-offset: 2px;
    overflow-wrap: break-word;
    position: absolute;
    top: 50%;
    touch-action: none;
    transform: translateY(-50%);
    transition: transform 200ms;
    user-select: none;
    width: 0.875rem;
    z-index: 1;
`;

const StyledTrack = styled(Box)`
    border-radius: 0.125rem;
    border: 0 solid #e2e8f0;
    position: absolute;
    overflow: hidden;
    overflow-wrap: break-word;
    top: 50%;
    transform: translateY(-50%);
    height: 0.25rem;
    background: ${({ index, leveragestate, leverage, redtheme, greentheme, maxleverage }) =>
        index === 1 ? '#e2e8f0' : leveragestate === Intent.DANGER || leverage > maxleverage ? redtheme : greentheme};
`;

function StrategyLeverageLabel({ isDesktopView }: { isDesktopView: boolean }) {
    const currentStrategyLeverage = useSelector(getSelectedStrategyLeverage);
    return (
        <HStack align="center">
            <Text
                textAlign="left"
                color="text.100"
                fontSize={isDesktopView ? 'md' : 'xs'}
                verticalAlign="center"
                cursor="help"
                fontWeight="medium"
            >
                Leverage
            </Text>
            <Text
                textAlign="left"
                color="text.100"
                fontSize={isDesktopView ? 'md' : 'xs'}
                verticalAlign="center"
                cursor="help"
            >
                {`(Current: ${currentStrategyLeverage || 0}x)`}
            </Text>
        </HStack>
    );
}
export default function LeverageSlider({
    orderSide,
    maxLeverage,
    isConnected,
    leverageState,
    setLeverageState,
    leverage,
    setLeverage,
    setAmount,
    price,
}: IProps) {
    const { t } = useTranslation();
    const [redTheme, greenTheme] = useToken('colors', ['brand.red.400', 'brand.green.400']);
    const isDesktopView = useScreenSize();
    const market = useSelector(getSelectedMarket);
    const currentStrategyAvailableCollateral = useSelector(getSelectedStrategyAvailableCollateral);
    const [triggerUpdate, setTriggerUpdate] = useState<boolean>(false);
    useOrderAmountUpdate(orderSide, setAmount, price, leverage, triggerUpdate, setTriggerUpdate);

    function updateSelectedLeverage(value: number) {
        if (market === undefined) return;
        let leverage = new BigNumber(value.toFixed(4)).toNumber();
        if (leverage > maxLeverage * FRONTEND_MAX_LEVERAGE_PERCENTAGE && leverage <= maxLeverage) {
            leverage = new BigNumber((maxLeverage * FRONTEND_MAX_LEVERAGE_PERCENTAGE).toFixed(4)).toNumber();
        }

        if (leverage <= maxLeverage) {
            setLeverage(leverage);
            setLeverageState(Intent.NONE);
        } else {
            setLeverage(new BigNumber(maxLeverage).toNumber());
            setLeverageState(Intent.DANGER);
        }
        setTriggerUpdate(true);
    }

    return (
        <FormControl id="leverage-slider" aria-label={t('leverage')}>
            <Flex justifyContent="space-between">
                <TooltipWrapper placement="bottom" hasArrow label={t('useTheSlider')} aria-label={t('useTheSlider')}>
                    <StrategyLeverageLabel isDesktopView={isDesktopView} />
                </TooltipWrapper>
            </Flex>
            <Flex w="100%">
                <StyledSlider
                    min={0}
                    max={maxLeverage}
                    disabled={!isConnected || new BigNumber(currentStrategyAvailableCollateral || 0).isZero()}
                    step={0.1}
                    defaultValue={0}
                    renderTrack={(
                        props: object,
                        state: {
                            index: number | undefined | readonly number[];
                            value: number | undefined | readonly number[];
                        },
                    ) => (
                        <StyledTrack
                            leveragestate={leverageState}
                            leverage={leverage}
                            index={state.index}
                            redtheme={redTheme}
                            greentheme={greenTheme}
                            maxleverage={maxLeverage}
                            {...props}
                        />
                    )}
                    renderThumb={(props: object) => <StyledThumb {...props} />}
                    value={leverage}
                    onChange={(e: number | readonly number[]) => {
                        if (typeof e === 'number') updateSelectedLeverage(e);
                    }}
                />
            </Flex>

            <HStack justify="space-between">
                <Text fontSize="xs" fontWeight="600">
                    {leverageState === Intent.DANGER || leverage > maxLeverage
                        ? t('maxValueExceeded')
                        : `${Math.round(leverage * 10) / 10}x`}
                </Text>
                <Text color="text.100" fontSize="xs">
                    {t('MAX')} {`${maxLeverage}x`}
                </Text>
            </HStack>
        </FormControl>
    );
}
