import { Button, ButtonGroup } from '@chakra-ui/react';
import { OrderSide } from '@derivadex/types';
import { BigNumber } from 'bignumber.js';
import { useOrderAmountAndLeverageUpdate } from 'hooks/useOrderAmountAndLeverageUpdate';
import { useScreenSize } from 'hooks/useScreenSize';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSelectedMarket } from 'store/market/selectors';
import { getSelectedStrategyAvailableCollateral } from 'store/strategy/selectors';

export enum FixedSize {
    One,
    Two,
    Three,
    Max,
    None,
}

interface IProps {
    maxLeverage: number;
    side: OrderSide;
    price: string;
    isConnected: boolean;
    setAmount: (arg: any) => any;
    setLeverage: (arg: any) => any;
    setLeverageState: (arg: any) => any;
}

export default function PercentageButtons({
    maxLeverage,
    side,
    price,
    isConnected,
    setAmount,
    setLeverage,
    setLeverageState,
}: IProps) {
    const { t } = useTranslation();
    const isDesktopView = useScreenSize();
    const market = useSelector(getSelectedMarket);
    const currentStrategyAvailableCollateral = useSelector(getSelectedStrategyAvailableCollateral);
    const [triggerUpdate, setTriggerUpdate] = useState<FixedSize>(FixedSize.None);

    useOrderAmountAndLeverageUpdate(
        side,
        setLeverageState,
        setLeverage,
        price,
        maxLeverage,
        triggerUpdate,
        setTriggerUpdate,
        setAmount,
    );
    function fixedOrder(size: FixedSize) {
        if (new BigNumber(currentStrategyAvailableCollateral || 0).isZero() || !market) return;
        setTriggerUpdate(size);
    }

    return (
        <>
            <ButtonGroup
                size={isDesktopView ? 'xs' : 'sm'}
                isDisabled={
                    isConnected && !new BigNumber(currentStrategyAvailableCollateral || 0).isZero() ? false : true
                }
            >
                <Button width="full" onClick={() => fixedOrder(FixedSize.One)} size={isDesktopView ? 'xs' : 'xs'}>
                    25%
                </Button>
                <Button width="full" onClick={() => fixedOrder(FixedSize.Two)} size={isDesktopView ? 'xs' : 'xs'}>
                    50%
                </Button>
                <Button width="full" onClick={() => fixedOrder(FixedSize.Three)} size={isDesktopView ? 'xs' : 'xs'}>
                    75%
                </Button>
                <Button width="full" onClick={() => fixedOrder(FixedSize.Max)} size={isDesktopView ? 'xs' : 'xs'}>
                    {t('MAX')}
                </Button>
            </ButtonGroup>{' '}
        </>
    );
}
