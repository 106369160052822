import { BigNumber } from '@0x/utils';
import { OrderSide } from '@derivadex/types';
import { leverageAfterOrder } from '@derivadex/utils';
import { Intent } from 'components/Marketplace/BuySell/Tabs/OrderForm';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useStore } from 'react-redux';
import { getMarkPricesAsList, getSelectedMarket } from 'store/market/selectors';
import { AppState } from 'store/slices';
import { getPositionsAsList, getSelectedStrategyAvailableCollateral } from 'store/strategy/selectors';

export function useLeverageUpdate(
    orderSide: OrderSide,
    setLeverageState: Dispatch<SetStateAction<Intent | null>>,
    setLeverage: Dispatch<SetStateAction<number>>,
    price: string,
    amount: string,
    maxLeverage: number,
    triggerUpdate: boolean,
    setTriggerUpdate: Dispatch<SetStateAction<boolean>>,
) {
    const store = useStore();
    const state = store.getState() as AppState;

    useEffect(() => {
        if (triggerUpdate) {
            const currentStrategyAvailableCollateral = getSelectedStrategyAvailableCollateral(state);
            const market = getSelectedMarket(state);
            const prices = getMarkPricesAsList(state);
            const positions = getPositionsAsList(state);
            const selectedMarketPrice = prices.find((it) => market?.symbol === it.symbol);
            let leverage = leverageAfterOrder(
                positions,
                new BigNumber(currentStrategyAvailableCollateral || 0).toNumber(),
                prices,
                orderSide,
                new BigNumber(amount === '' ? 0 : amount).toNumber(),
                new BigNumber(price === '' ? selectedMarketPrice?.price || 0 : price).toNumber(),
                market?.symbol || 'ETHP',
            ).toNumber();

            if (leverage !== undefined) {
                let leverageState = Intent.NONE;
                if (leverage >= maxLeverage) {
                    leverageState = Intent.DANGER;
                    leverage = maxLeverage;
                }
                setTriggerUpdate(false);
                setLeverageState(leverageState);
                setLeverage(leverage);
            }
        }
    }, [triggerUpdate]);
}
