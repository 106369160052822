export const shortenAddress = (address: string, length = 4): string => {
    return `${address.slice(0, length + 2)}…${address.slice(address.length - length)}`;
};

/***
 * Strips input string to [max 10 digits] [optional:  '.' + {0 - decimalPlaces} decimal places ]
 * Examples:
 * |  input    |  output  |
 * |-----------|----------|
 * | 10.       | 10.      |
 * | 10.0      | 10.0     |
 * | 10.01     | 10.01    |
 * | A10B      | 10       |
 * | 10,000    | 10000    |
 * | $10, 000. | 10000.   |
 *
 * Why? Because we need to allow strings like '10.' so users can get over the 'decimal hurdle'
 * to keep entering more decimal places. BigNumber(10.) will parse to '10', but
 * if we interpret '10.' as '10' users can never enter decimal values.
 * @param input any string
 * @param decimalPlaces optional, default 2 (market limit uses 4)
 * @returns stringy-decimal string like 10, 1000, 100., 100.0, 100.01
 */
export const stringyDecimal = (input: string, decimalPlaces = 2) => {
    const s = input.replace(/[^0-9\\.]/g, '');
    const parts = s.split('.');
    const part1 = parts.length > 0 ? parts[0].substr(0, 10) : '';
    const part2 = parts.length > 1 ? `.${parts[1].substr(0, decimalPlaces)}` : '';
    return part1 + part2;
};
