import { DownloadIcon } from '@chakra-ui/icons';
import { Box, Divider, Flex, Tooltip } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useScreenSize } from 'hooks/useScreenSize';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getMarketsAsList } from 'store/market/selectors';
import { getAdlHistoryFilters } from 'store/portfolio/selectors';
import { SET_ADL_HISTORY_FILTERS } from 'store/portfolio/slice';
import { buildUrl } from 'store/requestUtils';
import { getSelectedStrategy } from 'store/strategy/selectors';
import { getEthAddress } from 'store/web3/selectors';

export default function AdlTableHeader() {
    const isDesktopView = useScreenSize();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const markets = useSelector(getMarketsAsList);
    const filters = useSelector(getAdlHistoryFilters);

    const traderAddress = useSelector(getEthAddress);
    const strategy = useSelector(getSelectedStrategy);

    function updateMarketFilter(value: any) {
        if (value) {
            const filtersUpdated = { symbol: value.value };
            dispatch(SET_ADL_HISTORY_FILTERS(filtersUpdated));
        }
    }

    const options: { label: string; value: string }[] = [{ label: 'ALL', value: 'all' }].concat(
        markets.map((it) => ({ label: it.symbol, value: it.symbol })),
    );

    const chakraStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            cursor: 'pointer',
            minWidth: '3rem',
        }),
        dropdownIndicator: (prev: any, { selectProps }: any) => ({
            ...prev,
            '> svg': {
                transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
            },
            w: '0.75rem',
        }),
        singleValue: (provided: any, state: any) => ({
            ...provided,
            fontSize: '0.75rem',
            color: 'gray.200',
        }),
    };

    return (
        <Box mb="0.5rem">
            <Flex>
                <Select
                    menuPlacement="bottom"
                    onChange={updateMarketFilter}
                    value={options.find((it) => it.value === filters.symbol) || options[0]}
                    selectedOptionColorScheme="gray"
                    focusBorderColor="none"
                    variant={'unstyled'}
                    isSearchable={false}
                    useBasicStyles
                    menuPortalTarget={document.body}
                    chakraStyles={chakraStyles}
                    styles={{
                        menuPortal: (provided: any) => ({ ...provided, zIndex: 100 }),
                    }}
                    size={'sm'}
                    options={options}
                />
                <Divider orientation="vertical" ml="1rem" mr="1rem" h="1.5rem" border={0} alignSelf="center" />
                <a
                    href={buildUrl(
                        '/stats/api/v1',
                        { account: traderAddress!, strategy: strategy!.strategy },
                        'adl/export',
                        {
                            order: 'desc',
                        },
                    )}
                    target="_blank"
                    style={{ pointerEvents: 'inherit' }}
                >
                    <Tooltip label={'Export as csv'} fontSize="1.5vh" placement="right">
                        <DownloadIcon />
                    </Tooltip>
                </a>
            </Flex>
        </Box>
    );
}
