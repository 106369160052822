import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Button, chakra, CircularProgress, Link, Text, VStack } from '@chakra-ui/react';
import { OrderIntent, OrderType } from '@derivadex/types';
import Dialog from 'components/Dialog/Dialogs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getDisableOrderNotifications } from 'store/profile/selectors';
import { PlaceOrderUIState, SET_PLACE_ORDER_UI_STATE } from 'store/ui/slice';

interface ISubmitOrder {
    tryAgain: () => void;
    onClose: () => void;
    state: PlaceOrderUIState;
    orderIntent: OrderIntent;
}

export default function SubmitOrder({ tryAgain, onClose, state, orderIntent }: ISubmitOrder) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const disableOrderNotifications = useSelector(getDisableOrderNotifications);
    useEffect(() => {
        if (state === PlaceOrderUIState.TRANSACTION_CONFIRMED) {
            setTimeout(() => onClose(), 300);
        }
    }, [state, onClose]);

    function getOrderType(orderType: OrderType) {
        switch (orderType) {
            case OrderType.Limit:
                return 'Limit';
            case OrderType.Market:
                return 'Market';
            case OrderType.Stop:
                return 'Stop Limit';
            case OrderType.LimitPostOnly:
                return 'LimitPostOnly';
            default:
                return '';
        }
    }

    function getInfoDetails() {
        switch (state) {
            case PlaceOrderUIState.PENDING_WALLET_CONFIRMATION:
                return (
                    <Text fontSize="0.8rem" fontWeight="400" color="gray.600">
                        {t('confirmThisOrderWithinYourWallet')}
                    </Text>
                );
            case PlaceOrderUIState.PENDING_TRANSACTION_CONFIRMATIONS:
                return (
                    <Text fontSize="0.8rem" fontWeight="400" color="gray.600">
                        {'Waiting on order execution confirmation'}
                    </Text>
                );
            case PlaceOrderUIState.TRANSACTION_EXECUTION_FAILED:
                return (
                    <>
                        <Text fontSize="0.8rem" fontWeight="400" color="brand.red.300">
                            {'Order not executed.'}
                        </Text>
                        <Text>
                            <chakra.span>{t('seeLink')}</chakra.span>:{' '}
                            <Link
                                href={'https://docs.derivadex.io/faq/order-not-executed-error'}
                                isExternal
                                fontWeight="600"
                                textDecoration="underline"
                            >
                                <ExternalLinkIcon mx="2px" mb="2px" />
                            </Link>
                        </Text>
                    </>
                );
            case PlaceOrderUIState.TRANSACTION_VALIDATION_FAILED:
                return (
                    <>
                        <Text fontSize="0.8rem" fontWeight="400" color="brand.red.300">
                            {'Order failed validation.'}
                        </Text>
                    </>
                );
            case PlaceOrderUIState.TRANSACTION_CONFIRMED:
                return (
                    <Text fontSize="0.8rem" fontWeight="400" color="brand.green.300">
                        {'Order Executed Successfully'}
                    </Text>
                );
            default:
        }
    }

    function getFooterAction() {
        if (state === PlaceOrderUIState.WALLET_REJECTED) {
            return (
                <Button
                    minH="4rem"
                    textDecoration="none"
                    fontSize="1.5rem"
                    fontWeight="500"
                    p="1.5rem"
                    minWidth="2.5rem"
                    w="100%"
                    color="white"
                    onClick={() => tryAgain()}
                    colorScheme="pink"
                >
                    {t('tryAgain')}
                </Button>
            );
        }
    }

    const isOpen = () => {
        if (disableOrderNotifications) {
            dispatch(SET_PLACE_ORDER_UI_STATE(PlaceOrderUIState.NONE));
            return false;
        }
        return state !== PlaceOrderUIState.NONE;
    };

    return (
        <Dialog
            isOpen={isOpen()}
            onClose={onClose}
            header={t('placingYourOrder', {
                orderType: getOrderType(orderIntent.orderType),
            })}
            disableClose={[
                PlaceOrderUIState.PENDING_TRANSACTION_CONFIRMATIONS,
                PlaceOrderUIState.PENDING_WALLET_CONFIRMATION,
            ].includes(state)}
            footer={getFooterAction()}
        >
            <VStack>
                <CircularProgress
                    isIndeterminate={
                        ![
                            PlaceOrderUIState.TRANSACTION_EXECUTION_FAILED,
                            PlaceOrderUIState.TRANSACTION_VALIDATION_FAILED,
                            PlaceOrderUIState.TRANSACTION_CONFIRMED,
                            PlaceOrderUIState.WALLET_REJECTED,
                        ].includes(state)
                    }
                    min={0}
                    max={1}
                    size="100px"
                    thickness="3px"
                />
                <Text fontSize="1rem" fontWeight="500">
                    <>
                        {orderIntent.side === 'Bid' ? t('buying') : t('selling')} {orderIntent.amount.toString()}{' '}
                        {orderIntent.symbol} at a price of{' '}
                        {orderIntent.orderType === OrderType.Market
                            ? 'Market Price'
                            : `${orderIntent.price.toString()} USDC`}
                    </>
                </Text>
                {getInfoDetails()}
            </VStack>
        </Dialog>
    );
}
