import { BigNumber } from '@0x/utils';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { getOpenInterest, getSelectedMarket, getSelectedMarketMarkPrice } from 'store/market/selectors';
import { AppState } from 'store/slices';

import CommonStatsItem from './CommonStatsItem';
import { IStatsItem } from './Stats';

export default function OpenInterest({
    id,
    width,
    isDesktopView,
    symbol,
}: {
    id: number;
    width: number | undefined;
    isDesktopView: boolean;
    symbol: string;
}) {
    const openInterest = useSelector((state: AppState) => getOpenInterest(state, symbol));
    const markPrice = useSelector(getSelectedMarketMarkPrice);
    const selectedMarket = useSelector(getSelectedMarket);
    const item: IStatsItem = {
        header: t('openInterest'),
        value:
            openInterest && markPrice
                ? new BigNumber(openInterest.amount).multipliedBy(markPrice.price)
                : new BigNumber(0),
        visibleWidthOnDesktop: 1100,
        headerTooltip: t('openInterestHeaderTooltip'),
        valueTooltip:
            openInterest && selectedMarket
                ? `${openInterest.amount.toFixed()} ${selectedMarket.symbol.slice(0, -1)}`
                : '',
        thousandSeparator: true,
        decimalScale: 2,
    };
    return <CommonStatsItem id={id} isDesktopView={isDesktopView} item={item} width={width} />;
}
