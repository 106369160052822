import { Abi } from 'viem';

import * as Checkpoint from '../contract-artifacts/Checkpoint.json';
import * as Custodian from '../contract-artifacts/Custodian.json';
import * as ICollateral from '../contract-artifacts/ICollateral.json';
import * as IStake from '../contract-artifacts/IStake.json';
import * as SafeERC20Wrapper from '../contract-artifacts/SafeERC20Wrapper.json';

export const IStakeContractAbi = IStake.compilerOutput.abi as Abi;
export const CheckpointContractAbi = Checkpoint.compilerOutput.abi as Abi;
export const ERC20ContractAbi = SafeERC20Wrapper.compilerOutput.abi as Abi;
export const ICollateralContractAbi = ICollateral.compilerOutput.abi as Abi;
export const CustodianContractAbi = Custodian.compilerOutput.abi as Abi;
