import { Flex, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import ExplorerButton from 'components/Marketplace/OrderTables/ExplorerButton';
import NumberFormatMinimumValue from 'components/Marketplace/OrderTables/NumberFormatMinimumValue';
import Table from 'components/Table/Table';
import { addHexPrefix } from 'ethereumjs-util';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getLeaderboardOrdering, getLeaderboardTraderOrdering } from 'store/leaderboard/selectors';
import { FETCH_LEADERBOARD_DATA } from 'store/leaderboard/slice';
import { FetchDataState } from 'store/requestUtils';
import { shortenAddress } from 'utils/number_utils';

export default function LeaderboardTable({
    isDesktopView,
    data,
    paginationData,
    traderAddress,
    limit,
}: {
    isDesktopView: boolean;
    data: any[];
    paginationData: { isEnabled: boolean; currentPage: number; prevPage?: () => void; nextPage?: () => void };
    traderAddress: string | undefined;
    limit: number;
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const traderOrdering = useSelector(getLeaderboardTraderOrdering);
    const ordering = useSelector(getLeaderboardOrdering);
    const columnHelper = createColumnHelper<any>();

    const columns = [
        columnHelper.accessor((row, index) => index, {
            cell: (info) => {
                if (!traderAddress) {
                    // If no traderAddress, start counting rank from the first row
                    return <Text>{limit * (paginationData?.currentPage - 1) + info.getValue() + 1}</Text>;
                } else {
                    // If traderAddress is present, start ranking from the second row
                    return info.getValue() === 0 ? (
                        '-'
                    ) : (
                        <Text>{limit * (paginationData?.currentPage - 1) + info.getValue()}</Text>
                    );
                }
            },
            header: t('rank'),
        }),
        columnHelper.accessor('trader', {
            cell: (info) => (
                <Text>
                    {shortenAddress(addHexPrefix(info.getValue().slice(2)))}{' '}
                    {traderAddress?.toLowerCase() === addHexPrefix(info.getValue().slice(2)) ? '(YOU)' : ''}
                </Text>
            ),
            header: t('trader'),
        }),
        columnHelper.accessor('accountValue', {
            cell: (info) =>
                info.getValue() !== undefined ? (
                    <NumberFormatMinimumValue value={info.getValue()} useCurrencyPrefix={true} />
                ) : (
                    <Text>-</Text>
                ),
            header: t('accountValue'),
            meta: {
                isNumeric: true,
            },
        }),
        columnHelper.accessor('volume', {
            cell: (info) =>
                info.getValue() !== undefined ? (
                    <NumberFormatMinimumValue value={info.getValue()} useCurrencyPrefix={true} />
                ) : (
                    <Text>-</Text>
                ),
            header: t('volume'),
            meta: {
                isNumeric: true,
            },
        }),
        columnHelper.accessor('realizedPnl', {
            cell: (info) =>
                info.getValue() !== null ? (
                    <NumberFormatMinimumValue value={info.getValue()} useCurrencyPrefix={true} />
                ) : (
                    <Text>-</Text>
                ),
            header: t('realizedPnl'),
        }),
        columnHelper.accessor((row) => row, {
            cell: (info) => (
                <>
                    <Flex>
                        <ExplorerButton
                            label={t('accountExplorerButtonDescription')}
                            url={`account/${addHexPrefix(info.getValue().trader?.toLowerCase().slice(2))}`}
                        />
                    </Flex>
                </>
            ),
            header: 'Actions',
        }),
    ];

    const headerActions: Record<string, { onClick: () => void }> = {
        volume: {
            onClick: () =>
                dispatch(
                    FETCH_LEADERBOARD_DATA({
                        orderBy: 'volume',
                        order: traderOrdering !== 'volume' ? 'desc' : ordering === 'desc' ? 'asc' : 'desc',
                        page: FetchDataState.NextPage,
                        traderFilter: undefined,
                    }),
                ),
        },
        realizedPnl: {
            onClick: () =>
                dispatch(
                    FETCH_LEADERBOARD_DATA({
                        orderBy: 'pnl',
                        order: traderOrdering !== 'pnl' ? 'desc' : ordering === 'desc' ? 'asc' : 'desc',
                        page: FetchDataState.NextPage,
                        traderFilter: undefined,
                    }),
                ),
        },
        accountValue: {
            onClick: () =>
                dispatch(
                    FETCH_LEADERBOARD_DATA({
                        orderBy: 'accountValue',
                        order: traderOrdering !== 'accountValue' ? 'desc' : ordering === 'desc' ? 'asc' : 'desc',
                        page: FetchDataState.NextPage,
                        traderFilter: undefined,
                    }),
                ),
        },
    };
    return (
        <div className="exchange-leaderboard">
            <Table
                isDesktopView={isDesktopView}
                columns={columns}
                data={data}
                emptyText={t('noData')}
                sortBy={[]}
                paginationData={paginationData}
                headerActions={headerActions}
            />
        </div>
    );
}
