import { ArrowForwardIcon, CheckIcon } from '@chakra-ui/icons';
import { Flex, IconButton, Text, Tooltip, useTheme } from '@chakra-ui/react';
import { PaymentsHistory, PaymentsHistoryType, Strategy } from '@derivadex/types';
import { getFrontendLogger } from '@derivadex/utils';
import { createColumnHelper } from '@tanstack/react-table';
import NumberFormatMinimumValue from 'components/Marketplace/OrderTables/NumberFormatMinimumValue';
import Table from 'components/Table/Table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedStrategy, getStrategiesAsList } from 'store/strategy/selectors';
import { SET_ACTIVE_STRATEGY } from 'store/strategy/slice';
import { TOGGLE_STRATEGIES_DIALOG } from 'store/ui/slice';
import { getTwentyOneByteEthAddress } from 'store/web3/selectors';
import { sortBigNumber } from 'utils/sortType';

export default function StrategiesTable({ isDesktopView }: { isDesktopView: boolean }) {
    const { t } = useTranslation();

    const strategies = useSelector(getStrategiesAsList);
    const strategyActive = useSelector(getSelectedStrategy);
    const twentyOneByteTraderAddress = useSelector(getTwentyOneByteEthAddress);
    const dispatch = useDispatch();

    function selectStrategy(strategyId: string, twentyOneByteTraderAddress: string) {
        dispatch(TOGGLE_STRATEGIES_DIALOG());
        dispatch(SET_ACTIVE_STRATEGY({ strategyId, trader: twentyOneByteTraderAddress, isInitialLoad: false }));
    }

    const columnHelper = createColumnHelper<Strategy>();
    const columns = [
        columnHelper.accessor('strategy', {
            cell: (info: any) => <Text>{`${info.getValue()}`}</Text>,
            header: t('strategy'),
        }),
        columnHelper.accessor('availCollateral', {
            cell: (info) => <NumberFormatMinimumValue value={info.getValue()} />,
            header: isDesktopView ? t('availCollateral') : t('availCollateralMobile'),
            meta: {
                isNumeric: true,
            },
            sortingFn: sortBigNumber,
        }),
        columnHelper.accessor((row) => row, {
            cell: (info: any) => (
                <Text verticalAlign="middle">
                    {strategyActive?.strategy === info.getValue().strategy ? (
                        <CheckIcon />
                    ) : (
                        <Tooltip label={t('selectThisStrategy')} fontSize="1.5vh" placement="left">
                            <IconButton
                                aria-label={t('selectThisStrategy')}
                                isRound
                                icon={<ArrowForwardIcon />}
                                size="xs"
                                onClick={() => selectStrategy(info.getValue().strategy, twentyOneByteTraderAddress!)}
                            />
                        </Tooltip>
                    )}
                </Text>
            ),
            header: ' ',
        }),
    ];

    return (
        <div className="table-strategies" style={{ width: '100%' }}>
            <Table
                isDesktopView={isDesktopView}
                columns={columns}
                data={strategies}
                emptyText={t('noStrategies')}
                sortBy={[{ id: 'strategy', desc: true }]}
            />
        </div>
    );
}
