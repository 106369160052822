import { Box, Flex, Text, useTheme } from '@chakra-ui/react';
import { OrderIntentStatsResponseItem, StatsAPISuccessTimeseriesResponse } from '@derivadex/types';
import { createColumnHelper } from '@tanstack/react-table';
import CryptoIcon from 'components/CryptoIcon/CryptoIcon';
import ExplorerButton from 'components/Marketplace/OrderTables/ExplorerButton';
import NumberFormatMinimumValue from 'components/Marketplace/OrderTables/NumberFormatMinimumValue';
import Table from 'components/Table/Table';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { getOrdersHistoryCurrentPage, getOrdersHistoryData } from 'store/portfolio/selectors';
import { FETCH_ORDERS_HISTORY_DATA } from 'store/portfolio/slice';
import { FetchDataState } from 'store/requestUtils';
import { getSelectedStrategy } from 'store/strategy/selectors';
import { getEthAddress } from 'store/web3/selectors';
import { sortBigNumber } from 'utils/sortType';

import OrderIntentsTableHeader from '../Header/OrderIntentsTableHeader';

export default function OrderIntentsTable({ isDesktopView }: { isDesktopView: boolean }) {
    const dispatch = useDispatch();
    const currentStrategy = useSelector(getSelectedStrategy);
    const traderAddress = useSelector(getEthAddress);
    const { t } = useTranslation();
    const { colors } = useTheme();
    const data: StatsAPISuccessTimeseriesResponse<OrderIntentStatsResponseItem> | undefined =
        useSelector(getOrdersHistoryData);
    const currentPage: number = useSelector(getOrdersHistoryCurrentPage);

    useEffect(() => {
        if (!currentStrategy || !traderAddress || data !== undefined) {
            return;
        }
        dispatch(FETCH_ORDERS_HISTORY_DATA(FetchDataState.NextPage));
    }, [currentStrategy, traderAddress]);

    const nextPage = () => {
        dispatch(FETCH_ORDERS_HISTORY_DATA(FetchDataState.NextPage));
    };

    const prevPage = () => {
        dispatch(FETCH_ORDERS_HISTORY_DATA(FetchDataState.PrevPage));
    };

    const columnHelper = createColumnHelper<OrderIntentStatsResponseItem>();
    const columns = [
        columnHelper.accessor('symbol', {
            cell: (info) => (
                <Flex align="center">
                    <CryptoIcon currency={getCryptoIcon(info.getValue())} size={4} />
                    <Text ml="0.5rem" fontWeight="600">
                        {`${info.getValue()}`}
                    </Text>
                </Flex>
            ),
            header: t('symbol'),
            meta: {
                style: !isDesktopView
                    ? {
                          position: 'sticky',
                          left: 0,
                          zIndex: 10,
                          backgroundColor: `${colors.card[100]}`,
                      }
                    : null,
            },
        }),
        columnHelper.accessor('orderType', {
            cell: (info) => <Text>{info.getValue() === 0 ? 'Limit' : 'Market'}</Text>,
            header: t('type'),
        }),
        columnHelper.accessor('side', {
            cell: (info) => (
                <Text color={info.getValue() === 1 ? 'brand.red.300' : 'brand.green.300'}>
                    {info.getValue() === 1 ? 'Ask' : 'Bid'}
                </Text>
            ),
            header: t('side'),
        }),
        columnHelper.accessor('price', {
            cell: (info) => (
                <NumericFormat
                    value={info.getValue().toNumber()}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale
                    decimalScale={2}
                />
            ),
            header: t('price'),
            meta: {
                isNumeric: true,
            },
            sortingFn: sortBigNumber,
        }),
        columnHelper.accessor('amount', {
            cell: (info) => (
                <NumericFormat
                    value={info.getValue().toNumber()}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale
                />
            ),
            header: t('amount'),
            meta: {
                isNumeric: true,
            },
            sortingFn: sortBigNumber,
        }),
        columnHelper.accessor('remainingAmount', {
            cell: (info) => (
                <NumericFormat
                    value={info.getValue().toNumber()}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale
                />
            ),
            header: t('remaining'),
            meta: {
                isNumeric: true,
            },
            sortingFn: sortBigNumber,
        }),
        columnHelper.accessor((row) => row.price.multipliedBy(row.amount), {
            cell: (info) => <NumberFormatMinimumValue value={info.getValue()} />,
            header: t('notional'),
            meta: {
                isNumeric: true,
            },
            sortingFn: (rowA, rowB) =>
                rowA.original.price
                    .multipliedBy(rowA.original.amount)
                    .comparedTo(rowB.original.price.multipliedBy(rowB.original.amount)),
        }),
        columnHelper.accessor('createdAt', {
            cell: (info) => <div />,
            header: t('createdAt'),
        }),
        columnHelper.accessor((row) => row, {
            cell: (info) => (
                <Flex justifyContent="space-evenly">
                    <ExplorerButton
                        label={t('orderExplorerButtonDescription')}
                        url={`account/${traderAddress}/strategy/${info.getValue().strategyId}/order/${
                            info.row.original.orderHash
                        }`}
                    />
                </Flex>
            ),
            header: ' ',
        }),
    ];

    function getCryptoIcon(symbol: string | undefined) {
        if (symbol === 'BTCP') return 'BTC';
        return 'ETH';
    }

    return (
        <Box
            p="0.5rem"
            className={data?.success && data.value.length > 0 ? 'table-open-orders' : 'table-open-orders-empty'}
        >
            <OrderIntentsTableHeader />
            <Table
                isDesktopView={isDesktopView}
                columns={columns}
                data={data?.success && data.value.length > 0 ? data.value : []}
                emptyText={t('noData')}
                sortBy={[{ id: 'createdAt', desc: true }]}
                columnVisibility={{ createdAt: false }}
                paginationData={{ isEnabled: true, currentPage, nextPage, prevPage }}
            />
        </Box>
    );
}
